import AuthStore from '@stores/AuthStore';
import { getConfig } from '@utils/Env';

/**
 * Downloads a file from a signed URL.
 * @param {string} fileId - The ID of the file to download.
 * @param {Object} options - Optional parameters.
 * @param {string} [options.fileName] - The suggested filename for the download.
 * @param {string|null} [options.target] - The suggested filename for the download.
 * @throws {Error} Will throw an error if the file cannot be fetched or downloaded.
 */
export async function fileDownloadById(fileId, { fileName, target } = {}) {
    try {
        const url = await fileGetSignedUrlById(fileId, { fileName });

        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        if(target){
            link.target = target;
        }

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (err) {
        console.error('File download error:', err.message);
        throw err;
    }
}

/**
 * Fetches the signed URL of a file by its ID.
 * @param {string} fileId - The ID of the file to retrieve the signed URL for.
 * @param {Object} options - Optional parameters.
 * @param {string} [options.fileName] - The suggested filename for the download.
 * @returns {string} The signed URL of the file.
 * @throws {Error} Will throw an error if the signed URL cannot be fetched.
 */
export async function fileGetSignedUrlById(fileId, { fileName } = {}) {
    try {
        const url = new URL(getConfig('users_api') + `/files/${fileId}`);
        url.searchParams.append('version', 'latest');
        if (fileName) {
            url.searchParams.append('filename', fileName);
        }

        const file = await AuthStore.fetch(url.toString(), {
            method: 'GET',
        });

        if (!file.signed_url) {
            throw new Error('Error fetching signed URL.');
        }

        return file.signed_url;
    } catch (err) {
        console.error('Error fetching signed URL:', err.message);
        throw err;
    }
}
