'use strict';

import moment from 'moment';

import allNutrients from '../tables/nutrients';
import nutrKeys from '../tables/nutrient-order';
import allTags from '../tables/tags';

import { roundForHumans } from './Math';

import allRecommendations from '../tables/recommendations';

export function addNutrientSet(sets) {
    const nutrients = {};

    sets.forEach(set => {
        Object.keys(set).forEach(nutrNo => {
            nutrients[nutrNo] = nutrients[nutrNo] || 0;
            nutrients[nutrNo] += set[nutrNo];
        });
    });

    return nutrients;
}

export function factorEnvelope(envelope, factor = 1) {
    const ret = {};

    Object.keys(envelope).forEach(nutrNo => {
        const range = envelope[nutrNo];

        ret[nutrNo] = ret[nutrNo] || {};

        if (typeof range.min === 'number') {
            ret[nutrNo].min = range.min / factor;
        }

        if (typeof range.max === 'number') {
            ret[nutrNo].max = range.max / factor;
        }
    });

    return ret;
}

export function getNutrientsForMeals(meals, contents, defaultPortion = 1, defaultValue = 0, roundValues = false) {
    const nutrients = {};

    meals.forEach(meal => {
        let content = null, contribs = {};
        let portion = meal.logged_amount || defaultPortion || 1;

        if (meal.deleted) {
            return;
        }

        if (meal.recipe_uuid) content = contents[meal.recipe_uuid];
        if (meal.food_uuid) content = contents[meal.food_uuid];

        if ((meal.logged_grams || (meal.logged_grams === 0 && !meal.logged_milliliters)) && content && content.grams_per_serving && content.nutrients && content.nutrients.values) {
            // User explicitly logged a mass for a recipe or food thats measured in grams.
            Object.keys(content.nutrients.values).forEach(nutrNo => {
                contribs[nutrNo] = (content.nutrients.available[nutrNo] || content.nutrients.values[nutrNo])
                                 ? content.nutrients.values[nutrNo] / content.grams_per_serving * meal.logged_grams
                                 : null;

                if (roundValues) {
                    contribs[nutrNo] = Math.round(contribs[nutrNo]);
                }
            });
        } else if (meal.logged_milliliters && content && content.milliliters_per_serving && content.nutrients && content.nutrients.values) {
            // User explicitly logged a fixed volume of a recipe or food that is measured in volume
            Object.keys(content.nutrients.values).forEach(nutrNo => {
                contribs[nutrNo] = (content.nutrients.available[nutrNo] || content.nutrients.values[nutrNo])
                                 ? content.nutrients.values[nutrNo] / content.milliliters_per_serving * meal.logged_milliliters
                                 : null;
                if (roundValues) {
                    contribs[nutrNo] = Math.round(contribs[nutrNo]);
                }
            });
        } else if ((content && content.nutrients && content.nutrients.values)) {
            // User only ate a portion of a recipe or food multiple it's nutrition by the portion size
            Object.keys(content.nutrients.values).forEach(nutrNo => {
                contribs[nutrNo] = (content.nutrients.available[nutrNo] || content.nutrients.values[nutrNo])
                                 ? content.nutrients.values[nutrNo] * portion
                                 : null;
                if (roundValues) {
                    contribs[nutrNo] = Math.round(contribs[nutrNo]);
                }
            });
        }

        if (meal.water_intake_l > 0) {
            if (!contribs['FLU']) {
                contribs['FLU'] = 0
            }

            contribs['FLU'] += meal.water_intake_l * 1000;
        }

        Object.keys(contribs).forEach(nutrNo => {
            let nutrientValue = contribs[nutrNo] == null ? defaultValue : contribs[nutrNo];

            if (nutrientValue !== null && typeof nutrientValue !== 'undefined' && !isNaN(nutrientValue)) {
                nutrients[nutrNo] = nutrients[nutrNo] || 0;
                nutrients[nutrNo] = nutrients[nutrNo] + nutrientValue;
            }
        });
    });

    return nutrients;
}

export function getNutrientsForRecipes(recipes, factor = 1) {
    const nutrients = {};

    recipes.forEach(recipe => {
        if (!(recipe && recipe.nutrients && recipe.nutrients.values)) {
            return;
        }

        Object.keys(recipe.nutrients.values).forEach(nutrNo => {
            nutrients[nutrNo] = nutrients[nutrNo] || 0;
            nutrients[nutrNo] += (recipe.nutrients.values[nutrNo] || 0) * factor;
        });
    });

    return nutrients;
}

export function getNutrientsForIngredients(ingredients, contents, factor = 1) {
    const nutrients = {};

    if (!Array.isArray(ingredients)) {
        ingredients = [ingredients];
    }

    ingredients.forEach(ingredient => {
        let content = null;

        if (ingredient.recipe) content = contents[ingredient.recipe.uuid];
        if (ingredient.food) content = contents[ingredient.food.uuid];

        if (!(content && content.nutrients && content.nutrients.values)) {
            return;
        }

        Object.keys(content.nutrients.values).forEach(nutrNo => {
            nutrients[nutrNo] = nutrients[nutrNo] || 0;
            nutrients[nutrNo] += (content.nutrients.values[nutrNo] || 0) * factor;
        });
    });

    return nutrients;
}

export function convertAgeToBracket(age) {
    // the second comparison is backwards because forwards it messes up the syntax highlighting in Sublime Text.
    if (age.years < 1 && 6 > age.months) {
        return '0-6-mo';
    }

    if (age.years < 1 && age.months >= 6) {
        return '6-12-mo';
    }

    if (age.years >= 1 && age.years <= 3) {
        return '1-3-yr';
    }

    if (age.years >= 4 && age.years <= 8) {
        return '4-8-yr';
    }

    if (age.years >= 9 && age.years <= 13) {
        return '9-13-yr';
    }

    if (age.years >= 14 && age.years <= 18) {
        return '14-18-yr';
    }

    if (age.years >= 19 && age.years <= 30) {
        return '19-30-yr';
    }

    if (age.years >= 31 && age.years <= 50) {
        return '31-50-yr';
    }

    if (age.years >= 51 && age.years <= 70) {
        return '51-70-yr';
    }

    if (age.years > 70) {
        return '70+yr';
    }

    return false;
}

export function getDemographic(user) {
    if (!user) {
        return 'all-users';
    }

    if (!user.birthdate && !user.gender) {
        return 'all-users';
    }

    // First part is gender. There's also subcategories for females
    var gender = (user.gender || 'female'),
        age = {
            years: 30,
            months: 0,
        };

    if (user.birthdate) {
        age = {
            years: moment().diff(moment(user.birthdate), 'years'),
            months: moment().diff(moment(user.birthdate), 'months') % 12,
        };
    }

    // Only append the pregnant or lactating demographics if the user
    // is both female and within the correct age range
    if (user.gender == 'female' && age.years >= 14 && age.years <= 50) {
        if (user.pregnant) {
            gender += '-pregnant';
        }

        if (user.lactating) {
            gender += '-lactating';
        }
    }

    if (false === (age = convertAgeToBracket(age))) {
        return 'all-ages';
    }

    return [gender, age].join('-');
}

let _distributions = null;

export function resetDistributionMultipliers() {
    // Loops through every nutrient and if it has a distribution array, randomly
    // pick one of them to be the distribution for this days worth of meals.
    _distributions = {};

    Object.keys(allNutrients).forEach(nutrNo => {
        const nutrient = allNutrients[nutrNo];

        if (!nutrient.distributions) {
            return;
        }

        // Set and remember a nutrient distribution.
        _distributions[nutrNo] = nutrient.distributions[Math.floor(Math.random() * nutrient.distributions.length)];
    });
}

export function getDistributionMultiplier(nutrNo, field, mealType) {
    const defaults = {
        'min'  : {'Breakfast': .15, 'Lunch': .20, 'Dinner': .25},
        'ideal': {'Breakfast': .25, 'Lunch': .30, 'Dinner': .35, 'Snack': .10},
        'max'  : {'Breakfast': .35, 'Lunch': .35, 'Dinner': .40, 'Snack': .15},
    };

    if (_distributions === null) {
        resetDistributionMultipliers();
    }

    if (_distributions[nutrNo] &&
        _distributions[nutrNo][field] &&
        typeof _distributions[nutrNo][field][mealType] !== 'undefined') {

        return _distributions[nutrNo][field][mealType];
    }

    return defaults[field] && defaults[field][mealType] || 0;
}

/**
 * Generates a straw-man prescription for a given meal type from an
 * all day prescription. This function has side effects.
 */
export function computeDefaultMealRx(allDayRx, mealType, precise = false) {
    const rx = {
        meal_type: mealType,
        envelope: {},
        implicit: true,
    };

    Object.keys(allDayRx.envelope).forEach(nutrNo => {
        rx.envelope[nutrNo] = {};

        const range = allDayRx.envelope[nutrNo];
        let mult;

        if (typeof range.min !== 'undefined') {
            mult = getDistributionMultiplier(nutrNo, 'min', mealType);
            const min = precise ? range.min * mult : roundForHumans(range.min * mult);
            if (min > 0) {
                rx.envelope[nutrNo].min = min;
            }
        }

        if (typeof range.max !== 'undefined') {
            mult = getDistributionMultiplier(nutrNo, 'max', mealType);
            rx.envelope[nutrNo].max = precise ? range.max * mult : roundForHumans(range.max * mult);
        }
    });

    return rx;
}

export function convertEnvelopeToFilters(envelope, filters = {}, minMult = 1, factor = 1) {
    Object.keys(envelope).forEach(nutrNo => {

        if (!(allNutrients[nutrNo] && allNutrients[nutrNo].Filter)) {
            return;
        }

        const fieldName = allNutrients[nutrNo].Filter
        const range = envelope[nutrNo];
        const { min, max } = range;

        if (typeof min === 'number' && typeof max === 'number') {
            filters[fieldName] = {
                'gte': Math.round(min * minMult * 100) / 100 / factor,
                'lte': Math.round(max * 100) / 100 / factor,
            };
        } else if (typeof min !== 'number' && typeof max === 'number') {
            filters[fieldName] = {'lte': Math.round(max * 100) / 100 / factor};
        } else if (typeof min === 'number' && typeof max !== 'number') {
            filters[fieldName] = {'gte': Math.round(min * minMult * 100) / 100 / factor};
        }
    });

    return filters;
}

export function getIdealsFromEnvelope(envelope, factor = 1) {
    const ideals = {};

    Object.keys(envelope).forEach(nutrNo => {
        const range = envelope[nutrNo];

        if (typeof range.min === 'number' && typeof range.max !== 'number') {
            ideals[nutrNo] = range.min / factor;
        } else if (!typeof range.min === 'number' && typeof range.max === 'number') {
            ideals[nutrNo] = range.max * 0.5 / factor;
        } else if (typeof range.min === 'number' && typeof range.max === 'number') {
            ideals[nutrNo] = (range.min + range.max) / 2 / factor;
        }
    });

    return ideals;
}

export function getNeededNutrients(nutrients, envelope) {
    const needed = {};

    Object.keys(envelope).forEach(nutrNo => {
        const range = envelope[nutrNo];
        const value = nutrients[nutrNo] || 0;

        // There is insufficient nutrient in the meal
        if (typeof range.min === 'number' && typeof range.max === 'number' && value < range.min) {
            needed[nutrNo] = {
                'min': range.min - value,
                'max': range.max - value,
                'orig': value,
            };
            return;
        }

        // There is insufficient nutrient in the meal
        if (typeof range.min === 'number' && typeof range.max !== 'number' && value < range.min) {
            needed[nutrNo] = {
                'min': range.min - value,
                'orig': value,
            };
            return;
        }
    });

    if (!Object.keys(needed).length) {
        return needed;
    }

    // Loop again, making sure we don't bust maximums as well
    Object.keys(envelope).forEach(nutrNo => {
        const range = envelope[nutrNo];
        const value = nutrients[nutrNo] || 0;

        // Now make sure we limit the amount of any unneeded sources so as to not exceed
        // our maximum values
        if (typeof range.max === 'number' && typeof range.min === 'number' && value < range.min) {
            needed[nutrNo] = {
                'min': range.min - value,
                'max': range.max - value,
                'orig': value,
            };
            return;
        }

        if (typeof range.max === 'number' && typeof range.min === 'number' && value > range.min) {
            needed[nutrNo] = {
                //'min': range.min - value,
                'max': range.max - value,
                'orig': value,
            };
            return;
        }

        if (typeof range.max === 'number' && typeof range.min !== 'number') {
            needed[nutrNo] = {
                'max': range.max - value,
                'orig': value,
            };
            return;
        }
    });
    return needed;
}

export function addDefaultAddSwapTags({mealType, profile, meals, modalSettings, contents}) {

    const { avoidances = [], exclude_foods = [], equipment = [], diets = [], limit_tags = [], skill_level } = profile.preferences || {};

    // Add the users taste profile settings
    modalSettings.extraFilters = modalSettings.extraFilters || {};
    modalSettings.extraFilters['!foods'] = exclude_foods;
    modalSettings.defaultTags   = (modalSettings.defaultTags || []).concat(limit_tags);
    modalSettings.defaultAvoids = (modalSettings.defaultAvoids || []).concat(avoidances);
    modalSettings.defaultExcludes = (modalSettings.defaultExcludes || []).concat(allTags.equipment.tags.filter(e => !equipment.includes(e)));

    const alreadyInMeal = meals.filter(m => m.meal == mealType);

    let hasMain = false,
        hasSide = false;

    // Figure out if we have a main dish and a side dish already
    hasMain = alreadyInMeal.find(item => !item.side_dish);
    hasSide = alreadyInMeal.find(item => item.side_dish);

    if (skill_level == 'Beginner') {
        modalSettings.defaultExcludes.push('Intermediate');
        modalSettings.defaultExcludes.push('Advanced');
    } else if (skill_level == 'Intermediate') {
        modalSettings.defaultExcludes.push('Advanced');
    }

    if (mealType === 'Snack') {
        modalSettings.defaultTags.push(mealType);
    }

    if (mealType === 'Breakfast') {
        // Is there a main dish already?
        if (hasMain && hasSide) {
            // Just add the lunch tag.
            modalSettings.defaultTags.push(mealType);
        } else if (!hasMain && hasSide) {
            modalSettings.defaultTags.push(mealType);
        } else if (hasMain && !hasSide) {
            modalSettings.defaultTags.push('Breakfast Side Dish');
        } else {
            modalSettings.defaultTags.push(mealType);
        }
    }

    if (mealType === 'Lunch') {
        // Is there a main dish already?
        if (hasMain && hasSide) {
            // Just add the lunch tag.
            modalSettings.defaultTags.push(mealType);
        } else if (!hasMain && hasSide) {
            modalSettings.defaultTags.push(mealType);
        } else if (hasMain && !hasSide) {
            modalSettings.defaultTags.push('Lunch Side Dish');
        } else {
            modalSettings.defaultTags.push(mealType);
        }
    }

    if (mealType === 'Dinner') {
        // Is there a main dish already?
        if (hasMain && hasSide) {
            // Don't add any tags
        } else if (!hasMain && hasSide) {
            modalSettings.defaultTags.push('Main Dish');
        } else if (hasMain && !hasSide) {
            // Do we have a meal kit or ready made meal? Use a different tag.
            if (hasMain.meal_type === 'food' && contents[hasMain.food_uuid] && contents[hasMain.food_uuid].product_type === 'Meal Kit') {
                modalSettings.defaultTags.push('Dinner Meal Kit Side');
            } else if (hasMain.meal_type === 'food' && contents[hasMain.food_uuid] && contents[hasMain.food_uuid].product_type === 'Ready Made Meal') {
                modalSettings.defaultTags.push('Dinner Ready Made Side');
            } else {
                modalSettings.defaultTags.push('Side Dish');
            }
        } else {
            modalSettings.defaultTags.push('Main Dish');
        }
    }
}

export function limitEnvelopeByMaximums(inside, outside) {
    Object.keys(outside).forEach(nutrNo => {
        let range = outside[nutrNo];

        if (!(range && range.max)) {
            return;
        }

        inside[nutrNo] = inside[nutrNo] || {};

        // We need to constrain needed's max. If needed[nutrNo]['max'] > explicitRx[nutrNo]['max']
        // then we need to set needed[nutrNo]['max'] to the lower value.
        if (!inside[nutrNo].max) {
            inside[nutrNo].max = range.max;
        } else if (inside[nutrNo].max > range.max) {
            inside[nutrNo].max = range.max;
        }
    });

    return inside;
}


/**
 * Big Rotten Function that does a lot of the work of the Add/Swap interface.
 *
 * This function computes search parameters to:
 * 1. Meet the nutrition of the profile
 * @param {[type]} options.mealType      [description]
 * @param {[type]} options.excludeUuids  [description]
 * @param {[type]} options.meals         [description]
 * @param {[type]} options.prescriptions [description]
 * @param {[type]} options.recipes       [description]
 * @param {[type]} options.modalSettings [description]
 */
export function addDefaultAddSwapFilters({profile, mealType, excludeUuids = [], meals = [], contents = {}, modalSettings = {}}) {
    const allDayRx = profile.prescriptions.filter(p => p.meal_type === 'all-day')[0];

    // Add the skipped content to the exclusions
    if (excludeUuids) {
        modalSettings.extraFilters['!uuid'] = modalSettings.extraFilters['!uuid'] || [];
        modalSettings.extraFilters['!uuid'] = modalSettings.extraFilters['!uuid'].concat(excludeUuids);

        modalSettings.extraFilters['!main_dish'] = modalSettings.extraFilters['!main_dish'] || [];
        modalSettings.extraFilters['!main_dish'] = modalSettings.extraFilters['!main_dish'].concat(excludeUuids);

        modalSettings.extraFilters['!side_dish'] = modalSettings.extraFilters['!side_dish'] || [];
        modalSettings.extraFilters['!side_dish'] = modalSettings.extraFilters['!side_dish'].concat(excludeUuids);
    }

    // If the profile is pregnant, avoid the NOPREG tag.
    if (profile.pregnant) {
        modalSettings.defaultExcludes = modalSettings.defaultExcludes || [];
        modalSettings.defaultExcludes.push('NOPREG');
    }

    // If the profile has a birthdate, and is 12 or under, add the kid friendly tag, otherwise exclude the toddler tag
    if (profile.birthdate) {
        const age = moment().diff(profile.birthdate, 'year');
        if (age > 12) {
            modalSettings.defaultExcludes = modalSettings.defaultExcludes || [];
            modalSettings.defaultExcludes.push('Toddler');
        } else {
            modalSettings.defaultTags.push('Kid Friendly');
        }
    }

    // Make sure that we're not going to exceed the patients prescription for this meal
    const explicitRx = profile.prescriptions.filter(p => p.meal_type === mealType)[0]
    const rx = explicitRx || (allDayRx && computeDefaultMealRx(allDayRx, mealType, true));

    // User does not have a prescription - don't limit.
    if (!rx) {
        return modalSettings;
    }

    // Add up some useful nutrient information
    const allDayNutrients = getNutrientsForMeals(meals, contents, profile.portion);
    const mealNutrients = getNutrientsForMeals(meals.filter(m => m.meal === mealType), contents, profile.portion);

    // Create a convenience envelope we can manipulate. We don't
    // want to change the original because that'll cause some seriously
    // unwanted and confusing side-effects for the user.
    const envelope = JSON.parse(JSON.stringify(rx.envelope)); // deep copy the envelope
    const hasMealTypes = [mealType];

    // What other meals do we have in this day?
    const sameDayMeals = meals.filter(item => (
        !item.deleted &&
        item.meal !== mealType
    ));

    // Count the meal types we've currently got.
    meals.forEach(m => {
        if (!hasMealTypes.includes(m.meal)) {
            hasMealTypes.push(m.meal);
        }
    });

    // Snacks have special logic that defines how their add/swap parameters work.
    // Snacks are used as cleanup - get whatever else you need for the day that
    // you didn't get from your main course meals.
    if (mealType === 'Snack') {
        // If we don't have the rest of our meals filled out already,
        // we'll just use the provided snack prescription.
        if (!(hasMealTypes.includes('Breakfast') &&
              hasMealTypes.includes('Lunch') &&
              hasMealTypes.includes('Dinner'))) {

            modalSettings.extraFilters = convertEnvelopeToFilters(
                envelope, modalSettings.extraFilters, 1, profile.portion
            );

            return modalSettings;
        }

        const snacks = meals.filter(m => m.meal === 'Snack');

        // Subtract the nutrients for the day from the all day prescription
        let needed = getNeededNutrients(allDayNutrients, allDayRx.envelope);

        // If we still need something, we should use what we need to get it.
        if (Object.keys(needed).length) {
            // Clamp needed to less than envelope maximums
            if (explicitRx) {
                needed = limitEnvelopeByMaximums(needed, explicitRx.envelope);
            }

            modalSettings.extraFilters = convertEnvelopeToFilters(
                needed,
                modalSettings.extraFilters,
                1,
                profile.portion
            );

            return modalSettings;
        }
    }

    // Subtract any content still in this meal from the envelope first
    Object.keys(envelope).forEach(nutrNo => {
        const range = envelope[nutrNo];
        const planned = mealNutrients[nutrNo] || 0;

        if (!planned) {
            return;
        }

        if (range.min && !range.max) {
            range.min = range.min - planned;
        } else if (!range.min && range.max) {
            range.max = range.max - planned;
        } else if (range.min && range.max) {
            range.min = range.min - planned;
            range.max = range.max - planned;
        }

        if (range.min < 0) {
            delete range.min;
        }

        if (range.max < 0) {
            delete range.max;
        }

        envelope[nutrNo] = range;
    });

    // If we're not adding our last meal, use the per-meal envelope nutrition constraints
    if (!(hasMealTypes.includes('Breakfast') &&
          hasMealTypes.includes('Lunch') &&
          hasMealTypes.includes('Dinner') &&
          hasMealTypes.includes('Snack'))) {

        modalSettings.extraFilters = convertEnvelopeToFilters(envelope, modalSettings.extraFilters, 1, profile.portion);

        // Try to find the center of the envelope
        // modalSettings.envelope = envelope;
        modalSettings.ideals = getIdealsFromEnvelope(envelope, profile.portion);

        return modalSettings;
    }

    // Otherwise, we're adding our last meal, and we need to make sure we don't bust through the
    // daily totals with our pick. So the meal selection has to match both the per-meal prescription
    // and, in combination with the other meals planned for the day, the all day prescription.
    const sameDayNutrients = getNutrientsForMeals(sameDayMeals, contents, profile.portion);

    // If we're picking the last meal, we need to make sure we get enough of what's left
    // AND we need to make sure we don't go over on anything either.
    Object.keys(envelope).forEach(nutrNo => {
        envelope[nutrNo] = envelope[nutrNo] || {}

        const allDayRange = allDayRx.envelope[nutrNo];
        const rxRange = envelope[nutrNo];

        // If we're adding, include the meals already in the meal slot (mealNutrients)
        // If we're swapping, don't include the meals already in the slot, they'll get replaced
        const consumed = (sameDayNutrients[nutrNo] || 0) + (mealNutrients[nutrNo] || 0);

        if (!allDayRange) {
            return;
        }

        // Since we're the last meal, make sure we get enough of stuff.
        if (typeof allDayRange.min === 'number' &&
            typeof rxRange.min === 'number') {
            let remainingNeeded = allDayRange.min - consumed;

            if (rxRange.min < remainingNeeded) {
                rxRange.min = remainingNeeded;
            }

            if (rxRange.min <= 0) {
                delete rxRange.min;
            }
        }

        // But not TOO much stuff...
        if (typeof allDayRange.max === 'number' &&
            typeof rxRange.max == 'number')  {
            let maxRemaining = allDayRange.max - consumed;

            if (rxRange.max > maxRemaining) {
                rxRange.max = maxRemaining;
            }

            if (rxRange.max < 0) {
                rxRange.max = 0;
            }
        }
    });

    // Convert the envelope to a search filters
    modalSettings.extraFilters = convertEnvelopeToFilters(envelope, modalSettings.extraFilters, 1, profile.portion);

    // Try to find the center of the envelope
    // modalSettings.envelope = envelope;
    modalSettings.ideals = getIdealsFromEnvelope(envelope, profile.portion);
}

/**
 * Turns a regular nutrition prescription into a super lenient restaurant nutrition prescription
 * because restaurant food is bad for you, we have to relax our contraints quite a bit.
 *
 *
 * @param  {[type]} rxs [description]
 * @return {[type]}     [description]
 */
export function transformEnvelopeForRestaurants(envelope, allDayEnvelope, superLenient = false) {
    const outputRx = {};

    // Calories, use both min and max
    if (envelope && envelope['208']) {
        outputRx['208'] = {};
        if (envelope['208'].min) outputRx['208'].min = envelope['208'].min * 0.75;
        if (envelope['208'].max) outputRx['208'].max = envelope['208'].max;
    }

    // Protein, use both min and max
    if (envelope && envelope['203']) {
        outputRx['203'] = {};
        if (envelope['203'].min) outputRx['203'].min = envelope['203'].min * 0.75;
        if (envelope['203'].max) outputRx['203'].max = envelope['203'].max;
    }

    // Carbs, use only the maximum
    if (envelope && envelope['205'] && envelope['205'].max) {
        outputRx['205']     = {};
        outputRx['205'].max = envelope['205'].max;
    }

    // Fat, use only the maximum
    if (envelope && envelope['204'] && envelope['204'].max) {
        outputRx['204']     = {};
        outputRx['204'].max = envelope['204'].max;
    }

    // Sodium, use the all day rx
    if (!superLenient && allDayEnvelope && allDayEnvelope['307'] && allDayEnvelope['307'].max) {
        outputRx['307']     = {};
        outputRx['307'].max = allDayEnvelope['307'].max / 2;
    }

    return outputRx;
}

export function nutrNoSortCmp(a, b) {
    const aI = nutrKeys.indexOf(a),
          bI = nutrKeys.indexOf(b);

    if (aI > bI) return 1;
    if (aI < bI) return -1;
    return 0;
}

export function compareNutrientsToEnvelope(nutrients, envelope, ignore = []) {
    const deviations = [];

    Object.keys(envelope).forEach(nutrNo => {
        if (ignore.includes(nutrNo)) {
            return;
        }

        const range = envelope[nutrNo];

        let value = (nutrients && nutrients[nutrNo]) || 0;
        value = Math.round(value * 100) / 100;

        if (typeof range.min !== 'undefined' && value < range.min) {
            deviations.push({
                ...range,
                nutrNo,
                value
            });
        }

        if (typeof range.max !== 'undefined' && value > range.max) {
            deviations.push({
                ...range,
                nutrNo,
                value
            });
        }
    });

    return deviations;
}

export function computeWeeklyAnalysis(weekStart, profile, allMeals, assets) {
    if (!profile) {
        return {};
    }

    const weekEnd = moment(weekStart).add(6, 'days');
    const demographic = getDemographic(profile);
    const dri = {...allRecommendations['all-ages'], ...allRecommendations[demographic]};
    const meals = allMeals.filter(meal => weekStart.isSameOrBefore(meal.date, 'day') &&
                                          weekEnd.isSameOrAfter(meal.date, 'day'));
    const loggedMeals = meals.filter(meal => meal.logged_portion);
    const nutrients = getNutrientsForMeals(loggedMeals, assets, profile.portion);
    const allDayRx = profile.prescriptions.find(p => p.meal_type === 'all-day');
    const rxs = allDayRx ? {'all-day': allDayRx.envelope} : {};
    const validMealTypes = ['Breakfast', 'Lunch', 'Dinner', 'Snack'];

    validMealTypes.forEach(mealType => {
        const prescription = profile.prescriptions.find(p => p.meal_type === mealType) ||
               (allDayRx && computeDefaultMealRx(allDayRx, mealType, true));

        if (!prescription) {
            return;
        }

        rxs[mealType] = prescription.envelope;
    });

    const analysis = {
        vegetables: {
            value: Math.round(nutrients['VEG'] || 0),
            total: ((allDayRx && allDayRx['VEG'] && allDayRx['VEG'].min) || dri['VEG']) * 7,
            percentage: 75,
        },
        fruits: {
            value: Math.round(nutrients['FRU'] || 0),
            total: ((allDayRx && allDayRx['FRU'] && allDayRx['FRU'].min) || dri['FRU']) * 7,
            percentage: 75,
        },
        meals: {
            total: 0,
            within_calories: 0,
            percentage: 0,
            restaurant: 0,
            recipes: 0,
        },
        logging: {
            logged: 0,
            total: meals.length,
            percentage: 0,
        },
    };

    const grouped = {};

    loggedMeals.forEach(meal => {
        const key = meal.meal + '-' + meal.date;

        grouped[key] = grouped[key] || {mealType: meal.meal, date: meal.date, meals: []};
        grouped[key].meals.push(meal);

        if (['fresh', 'leftover'].includes(meal.meal_type)) {
            analysis.meals.recipes++;
        }

        if (['food'].includes(meal.meal_type)) {
            const food = assets[meal.food_uuid];

            if (food?.product_type === 'Restaurant Dish') {
                analysis.meals.restaurant++;
            }
        }
    });

    analysis.logging.logged = Object.keys(grouped).length;
    analysis.vegetables.percentage = analysis.vegetables.total > 0
                               ? Math.round(analysis.vegetables.value / analysis.vegetables.total * 100)
                               : 0;
    analysis.fruits.percentage = analysis.fruits.total > 0
                               ? Math.round(analysis.fruits.value / analysis.fruits.total * 100)
                               : 0;

    analysis.logging.percentage = analysis.logging.total > 0
                                ? Math.round(analysis.logging.logged / analysis.logging.total * 100)
                                : 0;

    Object.keys(grouped).forEach(key => {
        let mealNutes = grouped[key].nutrients = getNutrientsForMeals(grouped[key].meals, assets, profile.portion);

        // Is this meal within calorie limits?
        if (rxs[grouped[key].mealType] && rxs[grouped[key].mealType]['208']) {

            const range = rxs[grouped[key].mealType]['208'];

            if (range.min && range.max && mealNutes['208'] > range.min && mealNutes['208'] < range.max) {
                analysis.meals.within_calories++;
            } else if (range.min && !range.max && mealNutes['208'] > range.min) {
                analysis.meals.within_calories++;
            } else if (!range.min && range.max && mealNutes['208'] < range.max) {
                analysis.meals.within_calories++;
            }
        }
    });

    analysis.meals.percentage = analysis.meals.within_calories > 0
                              ? Math.round(analysis.meals.within_calories / analysis.meals.total * 100)
                              : 0;

    return analysis;
}
