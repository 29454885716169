'use strict';

import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from 'react-modal';
import Helmet from 'react-helmet';
import debounce from 'lodash.debounce';
import indexBy from 'lodash.indexby';
import classNames from 'classnames';
import platform from 'platform';

import SpringHero from '../components/MealDetails/SpringHero.react';
import RecipeIngredients from '../components/Modules/RecipeIngredients.react';
import FoodIngredients from '../components/Modules/FoodIngredients.react';
import RecipeInstructions from '../components/Modules/RecipeInstructions.react';
import RecipeEditor from '../components/Recipes/Editor.react';
import RecipeToPdfButton from '../components/Recipes/RecipeToPdfButton.react';
import EditImage from '../components/Recipes/Editor/EditImage.react';
import MyTabs from '../components/Widgets/Tabs.react';
import Popup from '../components/Widgets/Popup.react';
import NutritionInfo from '../components/Products/NutritionInfo.react';
import HowMuchToEat from '../components/Recipes/HowMuchToEat.react';
import Dropdown from '../pro/components/Widgets/Dropdown.react';
import Merchant from '../components/Recipes/Merchant.react';
import SharePopup from '../components/Widgets/SharePopup.react';
import Orders from '../components/MealDetails/Orders.react';
import SwapMenu from '../components/Dashboard/SwapMenu.react';
import LocationInfo from '../components/Products/LocationInfo.react';
import AddToCollectionButton from '../components/Recipes/AddToCollectionButton.react';
import Alert from '../components/Widgets/Alert/Alert.react';
import SchedulingModal from '../components/Recipes/SchedulingModal.react';
import FoodEditorModal from '../components/Foods/Modals/FoodEditorModal';
import ConfirmOverwriteModal from '../components/Planner/Modals/ConfirmOverwriteModal.react';
import FoodUnitsSelector from '../components/DailyLog/FoodUnitsSelectorModal.react';

import LoginForm from '../components/Widgets/LoginForm.react';
import Footer from '../components/Widgets/footer/Footer.react';

import MealStore from '../stores/MealStore';
import MealActions from '../actions/MealActions';
import UserStore from '../stores/UserStore';
import GroceryActions from '../actions/GroceryActions';

import Analytics from '../utils/Analytics';

import { getConfig } from '../utils/Env';
import { toHuman } from '../utils/Duration';
import {
    updateMealLeftovers,
    mealSortCompare,
    getParticipantsForMeal,
    getPrimaryMeal,
    getServingsNeededWithLoggedServings,
    getLoggedServingsOfRecipe,
} from '../utils/Meals';
import { isMealInGroceries } from '../utils/Grocery';
import { getNutrientsForMeals, computeDefaultMealRx, compareNutrientsToEnvelope } from '../utils/Nutrition';
import { ScreenService } from '../utils/ScreenService';

import './MealDetails.scss';
import '../components/FeedModals.scss';
import GroceryButton from '../components/Groceries/GroceryButton/GroceryButton.react';
import MealTitle from '../components/Meals/MealTitle.react';
import MultiSharePopup from '../components/Widgets/MultiSharePopUp.react';


const properMealOrder = ['Breakfast', 'Snack', 'Lunch', 'Dinner'];
const sections = ['first', 'second', 'third', 'fourth', 'fifth'];

export default class MealDetails extends Component {
    static propTypes = {
        profile: PropTypes.object,

        hidePrintBtn: PropTypes.bool,
        hideEditBtns: PropTypes.bool,
    };

    static defaultProps = {
        hidePrintBtn: false,
        hideEditBtns: false,
    };

    static contextTypes = {
        confirm: PropTypes.func,
        router: PropTypes.object,
        location: PropTypes.object,
        isMobile: PropTypes.bool,
        isCordova: PropTypes.bool,
        isPro: PropTypes.bool,
        breakpoint47em: PropTypes.bool,

        defaultMealType: PropTypes.string,

        meals: PropTypes.array,
        recipes: PropTypes.object,
        details: PropTypes.object,
        foods: PropTypes.object,
        merchants: PropTypes.object,
        locations: PropTypes.object,
        brands: PropTypes.object,

        groceries: PropTypes.array,

        editMealBatches: PropTypes.func,
        onModifyMeal: PropTypes.func,
        onModifyMeals: PropTypes.func,
        onRemoveMeals: PropTypes.func,
        startAddMeal: PropTypes.func,
        startReplaceMeal: PropTypes.func,
        showUpgradeForm: PropTypes.func,
    };

    constructor(props, context) {
        super(props, context);

        const profile = this.getProfileFromProps(props, context);
        const meals = this.getMealsFromProps(props, context);

        this.state = {
            profile,
            ...this.getSyncState(props, context, meals, profile),

            dishIndex: context?.location?.query?.dishIndex || 0,
            nutrientsIndex: context?.location?.query?.nutrientsIndex || 0,
            editMealModal: null,
        };

        this.scrollable = createRef();
        this.syncScaling = debounce(this.syncScaling, 500);
        this.nutrientTabsRef = React.createRef();
    }

    componentDidMount = () => {
        ScreenService.keepAwake();
        UserStore.addChangeListener(this.onUserStoreChange);
        MealStore.addChangeListener(this.onMealStoreChange);

        const { mealType, date, meals } = this.state;

        Analytics.viewMeal({
            'Meal UUIDs': meals.map((m) => m.uuid),
            'Meal Type': mealType,
            Date: moment(date).format('YYYY-MM-DD'),
        });

        this.syncScaling();
    };

    componentWillUnmount = () => {
        ScreenService.allowSleep();
        UserStore.removeChangeListener(this.onUserStoreChange);
        MealStore.removeChangeListener(this.onMealStoreChange);
    };

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        const profile = this.getProfileFromProps(nextProps, nextContext);
        const meals = this.getMealsFromProps(nextProps, nextContext);

        this.setState(this.getSyncState(nextProps, nextContext, meals, profile), this.syncScaling);
    };

    onUserStoreChange = () => {
        const profile = this.getProfileFromProps(this.props, this.context);
        const meals = this.getMealsFromProps(this.props, this.context);

        this.setState(this.getSyncState(this.props, this.context, meals, profile), this.syncScaling);
    };

    onMealStoreChange = () => {
        const profile = this.getProfileFromProps(this.props, this.context);
        const meals = this.getMealsFromProps(this.props, this.context);
        this.setState(this.getSyncState(this.props, this.context, meals, profile), this.syncScaling);
    };

    getMealsFromProps = (props, context) => {
        return props.meals || context.meals || MealStore.getMeals();
    };

    getProfileFromProps = (props, context) => {
        return props.profile || context.profile || UserStore.getUser();
    };

    getSyncState = (props, context, allMeals, profile) => {
        const { params } = props;
        const { mealType = 'Dinner' } = params;
        const { recipes, foods } = context;

        if (!profile) {
            return { meals: [], date, mealType, primary: null, participants: [], profile, mismatches: [] };
        }

        const date = params.date ? moment(params.date) : moment();
        const meals = allMeals
            .filter((m) => !m.deleted && date.isSame(m.date, 'day') && m.meal === mealType)
            .filter((m) => !['note'].includes(m.meal_type))
            .sort((a, b) => mealSortCompare(a, b, true));

        const primary = getPrimaryMeal(meals, recipes, foods);
        const allContent = { ...recipes, ...foods };
        const nutrients = getNutrientsForMeals(meals, allContent, profile.portion);

        if (!meals.length) {
            return { meals: [], date, mealType, primary: null, participants: [], profile, mismatches: [] };
        }

        // Find the daily rx
        const allDayRx = ((profile && profile.prescriptions) || []).filter((p) => p.meal_type === 'all-day')[0];

        // Provide each item with a copy of the per-meal prescription (useful later)
        const rxIndex = indexBy(profile.prescriptions || [], 'meal_type');
        let mealRx = rxIndex[primary.primary.meal];
        mealRx = mealRx || (allDayRx ? computeDefaultMealRx(allDayRx, primary.primary.meal, true) : {});
        let mismatches =
            meals.length && mealRx.envelope && nutrients
                ? compareNutrientsToEnvelope(nutrients, mealRx.envelope, ['FRU'])
                : [];
        let isMismatched = meals.length > 0 && mismatches.length > 0;
        let isOverLimits = mismatches.filter((miss) => miss.value > miss.max).length > 0;

        return {
            meals,
            date,
            mealType,
            primary,
            participants: getParticipantsForMeal(primary.primary, profile),
            profile,
            mismatches,
            isMismatched,
            isOverLimits,
        };
    };

    // If the participants + leftovers become desynced from the scaling, this fixes it automatically.
    syncScaling = () => {
        const { profile, meals } = this.state;
        const { recipes, onModifyMeals, location } = this.context;

        const dishIndex = location?.query?.dishIndex || 0;
        const nutrientsIndex = location?.query?.nutrientsIndex || 0;

        const dirtyMeals = [];

        meals.forEach((meal) => {
            if (!meal.recipe_uuid) {
                return;
            }

            const recipe = recipes[meal.recipe_uuid];
            if (!(recipe && recipe.servings > 0)) {
                return;
            }

            const participants = getParticipantsForMeal(meal, profile);
            const loggedServings = getLoggedServingsOfRecipe(meal, recipe);
            const neededPerMeal = getServingsNeededWithLoggedServings(profile, participants, loggedServings);

            // How many leftovers are there?
            const totalLeftovers = MealStore.getMeals().filter(
                (m) => m.meal_type == 'leftover' && !m.deleted && m.parent_uuid == meal.uuid
            ).length;
            const totalNeeded = neededPerMeal + totalLeftovers * neededPerMeal;
            const minScaling = Math.ceil(totalNeeded / recipe.servings);

            if (meal.scaling < minScaling) {
                meal.scaling = minScaling;

                dirtyMeals.push(meal);
            }
        });

        if (dirtyMeals.length > 0) {
            onModifyMeals(dirtyMeals);
        }

        // On resyncing, reset horizontal scroll
        this.scrollToDishIndex(dishIndex, nutrientsIndex);
    };

    getOrdersForMeal(meal) {
        const orders = [];
        if (!meal?.orders?.length) {
            return orders;
        }

        const allOrders = indexBy(MealStore.getOrders(), 'uuid');

        meal.orders.forEach((orderUuid) => {
            if (allOrders[orderUuid]) {
                orders.push(allOrders[orderUuid]);
            }
        });

        return orders;
    }

    renderMealControlButtons = (meal, content, canEditSingleMeal = true) => {
        const { meals, profile } = this.state;
        const { inhibit_swap = false, rd_override = false } = (profile && profile.preferences) || {};
        const { hideEditBtns } = this.props;
        const buttons = [];

        if (hideEditBtns || (inhibit_swap && !rd_override)) {
            return;
        }

        if (profile?.role === 'admin' && meal.food_uuid) {
            buttons.push(
                <a
                    key="admin"
                    target="_blank"
                    rel="noreferrer"
                    href={'https://' + getConfig('www_host') + `/admin/foods/${meal.food_uuid}`}
                >
                    <button className="edit-meal-btn">
                        <i className="feather feather-box" />
                        <em>admin</em>
                    </button>
                </a>
            );
        }

        if (profile?.role === 'admin' && meal.recipe_uuid) {
            buttons.push(
                <a
                    key="admin"
                    target="_blank"
                    rel="noreferrer"
                    href={'https://' + getConfig('www_host') + `/publisher/recipe/${meal.recipe_uuid}`}
                >
                    <button className="edit-meal-btn">
                        <i className="feather feather-box" />
                        <em>admin</em>
                    </button>
                </a>
            );
        }

        if (content.type == 'recipe') {
            buttons.push(<AddToCollectionButton key="collection" recipe={content} />);
        }

        if (canEditSingleMeal && !profile?.features?.inhibit_edit_recipe) {
            buttons.push(
                <button key="edit" className="edit-meal-btn" onClick={(ev) => this.editSingleMeal(meal, ev)}>
                    <i className="feather feather-edit" />
                    <em>Edit</em>
                </button>
            );
        }

        if (meals.length > 1) {
            buttons.push(
                <button key="delete" className="delete-dish-btn" onClick={(ev) => this.deleteSingleMeal(meal, ev)}>
                    <i className="feather feather-trash" />
                    <em>Remove</em>
                </button>
            );
        }

        return <div className="meal-control-btns">{buttons}</div>;
    };

    renderRecipe = (meal, recipe) => {
        const { meals, profile, dishIndex } = this.state;
        const { foods, recipes, details, merchants, editMealBatches } = this.context;

        const merchant = recipe.merchant && merchants[recipe.merchant.uuid];
        let participants = getParticipantsForMeal(meal, profile);
        const neededPerMeal = participants.reduce((total, member) => total + member.portion, 0);
        let parent = null;
        let hasChild = false;

        if (meal.meal_type == 'leftover') {
            parent = this.context.meals.find((item) => item.uuid === meal.parent_uuid);
        }

        if (meal.meal_type !== 'leftover') {
            hasChild = !!this.context.meals.find((item) => item.parent_uuid === meal.uuid);
        }

        // How much waste servings will this option produce?
        const totalServings = recipe.servings * meal.scaling;
        const totalDays = Math.floor(totalServings / neededPerMeal) || 1;

        let surplus = !hasChild && !parent;

        const eatThisMuchTabs = meals
            .map((meal, i) => {
                const dish = getPrimaryMeal([meal], recipes, foods);

                if (!(dish && (dish.recipe || dish?.food?.servings))) {
                    return null;
                }

                let factor = 1;

                if (parent) {
                    const daysPassed = moment(meal.date).diff(moment(parent.date), 'day');
                    if (daysPassed >= 1) {
                        const remainingServingsDenominator = meal.scaling * (meal.servings || recipe.servings || 1);
                        const remainingServingsNumerator = remainingServingsDenominator - neededPerMeal * daysPassed;
                        factor = remainingServingsNumerator
                            ? remainingServingsNumerator / remainingServingsDenominator
                            : 1;
                    }
                    if (totalDays - daysPassed == 1) {
                        surplus = true;
                    }
                }

                return (
                    <section key={i} data-title={'Dish ' + (i + 1)}>
                        <HowMuchToEat
                            dishNumber={meals.length > 1 ? i + 1 : 0}
                            meal={meal}
                            uuid={meal.uuid}
                            profile={profile}
                            recipe={dish.recipe || dish.food}
                            participants={participants}
                            factor={factor}
                            scaling={meal.scaling}
                            surplus={surplus}
                            onClick={() => editMealBatches && editMealBatches(meal)}
                            isLeftover={!!parent}
                        />
                    </section>
                );
            })
            .filter((v) => v);

        return (
            <>
                <div className={classNames('details-section', sections[this.section++])}>
                    <header>
                        <div className="section-title">
                            {meal.meal_type === 'leftover' && parent ? (
                                <p className="leftover-badge">
                                    Leftover from {moment(parent.date).format('ddd, MMM Do')}
                                </p>
                            ) : null}
                            {merchant ? (
                                <div className="t2 with-merchant">
                                    <Dropdown
                                        className="merchant-dropdown"
                                        button={
                                            <>
                                                {meals.length > 1 ? <span>DISH {dishIndex + 1}:&nbsp;</span> : null}
                                                <span>Recipe by</span> <em>{merchant.name}</em>{' '}
                                                <i className="feather feather-info" />
                                            </>
                                        }
                                    >
                                        <Merchant merchant={merchant} />
                                    </Dropdown>
                                </div>
                            ) : meals.length > 1 ? (
                                <p className="t2">DISH {dishIndex + 1}</p>
                            ) : null}
                        </div>

                        {this.renderMealControlButtons(meal, recipe, true)}
                    </header>

                    <div className="section-body">
                        <MealTitle
                            item={recipe}
                            meal={meal}
                            nutrientsIndex={dishIndex + 1}
                        />

                        <RecipeIngredients
                            recipe={recipe}
                            details={details[recipe.details]}
                            subdetails={details}
                            scaling={meal.scaling}
                            profile={profile}
                        />

                        <RecipeInstructions
                            recipe={recipe}
                            isMeal={true}
                            details={details[recipe.details]}
                            subdetails={details}
                            user={profile}
                        />
                    </div>
                </div>

                {recipe.servings ? (
                    <div className={classNames('details-section', sections[this.section++])}>
                        <header>
                            <div className="section-title">
                                <p className="t2">How Much to Eat</p>
                            </div>
                        </header>

                        <div className="section-body">
                            {eatThisMuchTabs.map(eatThisMuchTab => {
                                return eatThisMuchTab;
                            })}
                        </div>
                    </div>
                ) : null}
            </>
        );
    };

    renderPicsOrItDidntHappen = (food) => {
        if (!food.image && !food.nfp_image && !food.ingredients_image) {
            return;
        }

        return (
            <div className="image-editor">
                <div className="image-inputs">
                    {food.image ? (
                        <div className="image-input">
                            <EditImage image={food.image} showFooter={false} />
                        </div>
                    ) : null}

                    {food.nfp_image ? (
                        <div className="image-input">
                            <EditImage image={food.nfp_image} showFooter={false} />
                        </div>
                    ) : null}

                    {food.ingredients_image ? (
                        <div className="image-input">
                            <EditImage image={food.ingredients_image} showFooter={false} />
                        </div>
                    ) : null}
                </div>
            </div>
        );
    };

    renderFood = (meal, food) => {
        const { locations, brands, foods, recipes, editMealBatches } = this.context;
        const { meals, profile, dishIndex } = this.state;

        const location = (locations && locations[meal.location_uuid]) || null;
        const brand = (food.brand_uuid && brands && brands[food.brand_uuid]) || null;
        const currentLocation = UserStore.getLocation();

        const canEditFood = profile.uuid === food.owner && food.status !== 'live';

        const dishTitles = {
            'Restaurant Dish': (
                <>
                    <span>Order from</span> <em>{food.brand_name}</em>
                </>
            ),
            'Ready Made Meal': food.brand_name ? (
                <>
                    <span>Ready Made Meal by</span> <em>{food.brand_name}</em>
                </>
            ) : (
                'Ready Made Meal'
            ),
            'Brand Name Food': <span>Store Bought Item</span>,
            Food: <></>,
            UGC: <span>Custom Food</span>,
            'Meal Kit': food.brand_name ? (
                <>
                    <span>Meal kit by</span> <em>{food.brand_name}</em>
                </>
            ) : (
                'Meal kit'
            ),
        };

        let participants = getParticipantsForMeal(meal, profile);
        const neededPerMeal = participants.reduce((total, member) => total + member.portion, 0);
        let parent = null;
        let hasChild = false;

        if (meal.meal_type == 'leftover') {
            parent = this.context.meals.find((item) => item.uuid === meal.parent_uuid);
        }

        if (meal.meal_type !== 'leftover') {
            hasChild = !!this.context.meals.find((item) => item.parent_uuid === meal.uuid);
        }

        // How much waste servings will this option produce?
        const totalServings = food.servings * meal.scaling;
        const totalDays = Math.floor(totalServings / neededPerMeal) || 1;

        let surplus = !hasChild && !parent;

        let dishTitle = dishTitles[food.product_type] || food.product_type;
        const orders = this.getOrdersForMeal(meal);

        let foodPrice = null;

        if (food.mapped_from && food.servings) {
            const mf = food.mapped_from.find((mf) => mf.price);

            if (mf) {
                foodPrice = mf.price / food.servings;
            }
        }

        const eatThisMuchTabs = meals
            .map((meal, i) => {
                const dish = getPrimaryMeal([meal], recipes, foods);

                if (!(dish && (dish.recipe || dish?.food?.servings))) {
                    return null;
                }

                let factor = 1;

                if (parent) {
                    const daysPassed = moment(meal.date).diff(moment(parent.date), 'day');
                    if (daysPassed >= 1) {
                        const remainingServingsDenominator =
                            meal.scaling * (meal.servings || dish?.food?.servings || 1);
                        const remainingServingsNumerator = remainingServingsDenominator - neededPerMeal * daysPassed;
                        factor = remainingServingsNumerator
                            ? remainingServingsNumerator / remainingServingsDenominator
                            : 1;
                    }
                    if (totalDays - daysPassed == 1) {
                        surplus = true;
                    }
                }

                return (
                    <section key={i} data-title={'Dish ' + (i + 1)}>
                        <HowMuchToEat
                            dishNumber={meals.length > 1 ? i + 1 : 0}
                            meal={meal}
                            uuid={meal.uuid}
                            profile={profile}
                            recipe={dish.food || dish.recipe}
                            participants={participants}
                            factor={factor}
                            scaling={meal.scaling}
                            surplus={surplus}
                            onClick={() => editMealBatches && editMealBatches(meal)}
                            isLeftover={!!parent}
                        />
                    </section>
                );
            })
            .filter((v) => v);

        return (
            <>
                <section className={classNames('details-section', sections[this.section++])}>
                    {orders?.length ? (
                        <Orders orders={orders}>
                            <div>
                                <p className="t5">
                                    To change or edit your Sunbasket order, go to{' '}
                                    <a target="_blank" rel="noreferrer" href="https://sunbasket.com/">
                                        sunbasket.com
                                    </a>{' '}
                                    or contact <a href="https://sunbasket.com/help/">Sunbasket support</a>
                                </p>
                            </div>
                        </Orders>
                    ) : null}

                    <header>
                        <div className="section-title">
                            {meal.meal_type === 'leftover' && parent ? (
                                <p className="leftover-badge">
                                    Leftover from {moment(parent.date).format('ddd, MMM Do')}
                                </p>
                            ) : null}
                            <p className="t2">
                                {meals.length > 1 ? <span>DISH {dishIndex + 1}: </span> : null}
                                {dishTitle}
                            </p>
                        </div>

                        {this.renderMealControlButtons(meal, food, canEditFood)}
                    </header>

                    <div className="section-body">
                        <MealTitle
                            item={food}
                            meal={meal}
                            nutrientsIndex={dishIndex + 1}
                        />

                        {this.renderPicsOrItDidntHappen(food)}

                        {food.description ? <p className="t3 description">{food.description}</p> : null}

                        {food.ingredients ? (
                            <>
                                <p className="p3">This meal includes the following ingredients:</p>
                                <FoodIngredients food={food} profile={profile} />
                            </>
                        ) : null}

                        {food.modifications ? (
                            <p className="t3 modifications">
                                <em>Recommended modifications:</em> {food.modifications}
                            </p>
                        ) : null}

                        {food.nutrition_disclaimer ? (
                            <Alert type="warning">
                                <strong>Nutrition Disclaimer:</strong> {food.nutrition_disclaimer}
                            </Alert>
                        ) : null}

                        {food.gtin_upc?.length ? (
                            <p className="t3">
                                <em>Barcode:</em> {food.gtin_upc.join(', ')}
                            </p>
                        ) : null}
                    </div>
                </section>

                {location ? (
                    <div className={classNames('details-section', sections[this.section++])}>
                        <header>
                            <div className="section-title">
                                <p className="t2">Restaurant Information</p>
                            </div>
                        </header>

                        <div className="section-body">
                            <LocationInfo brand={brand} location={location} currentLocation={currentLocation} />
                        </div>
                    </div>
                ) : null}

                {food.preparation ? (
                    <div className={classNames('details-section', sections[this.section++])}>
                        <header>
                            <div className="section-title">
                                <p className="t2">Dish {meals.length === 1 ? null : dishIndex + 1} Instructions</p>
                            </div>
                        </header>

                        <div className="section-body">
                            <RecipeInstructions recipe={food} details={food} user={profile} />
                        </div>
                    </div>
                ) : null}

                {food.servings ? (
                    <div className={classNames('details-section', sections[this.section++])}>
                        <header>
                            <div className="section-title">
                                <p className="t2">How Much to Eat</p>
                            </div>
                        </header>

                        <div className="section-body">
                            {eatThisMuchTabs.map(eatThisMuchTab => {
                                return eatThisMuchTab;
                            })}
                        </div>
                    </div>
                ) : null}
            </>
        );
    };

    renderNutritionTabs = () => {
        const { dishIndex, nutrientsIndex, profile, meals } = this.state;
        const { recipes, foods, isPro } = this.context;

        const { hide_nutrition = false, rd_override = false } = (profile && profile.preferences) || {};

        if (!(meals && meals.length)) {
            return;
        }

        if (hide_nutrition && rd_override !== true) {
            return;
        }

        const allContent = { ...recipes, ...foods };
        const combinedNutrients = getNutrientsForMeals(meals, allContent, profile.portion, null);

        const nutrientTabs = [];

        nutrientTabs.push(
            <section key="combined" data-title="Combined">
                <h6 className="nutrition-title">Combined Nutrition for All Dishes</h6>
                <NutritionInfo profile={{ ...profile, portion: 1 }} nutrients={combinedNutrients} />
            </section>
        );

        meals.forEach((meal, i) => {
            const dish = getPrimaryMeal([meal], recipes, foods);

            if (!dish) {
                return null;
            }

            let dishNutrients = getNutrientsForMeals([meal], allContent, profile.portion, null);

            const { food } = dish;
            const showAllAvailable = food && profile.uuid === food.owner && food.status !== 'live';

            nutrientTabs.push(
                <section key={i} data-title={'Dish ' + (i + 1)}>
                    {meal.logged_amount ? (
                        <>
                            <h6 className="nutrition-title">
                                {`Nutrition for ${'Dish ' + (i + 1)}: ${dish?.food?.name || dish?.recipe?.title}`}
                            </h6>
                            <div className="nutrition-portion">
                                <p className="t2">
                                    Showing nutrition for {profile.name || `${profile.first_name} ${profile.last_name}`}
                                    :{' '}
                                </p>
                                {meal?.logged_amount && !isPro && !dish?.food?.servings ? (
                                    <FoodUnitsSelector
                                        food={dish.food || dish.recipe}
                                        meal={meal}
                                        replaceChevronText="edit"
                                        nutrientsIndex={i + 1}
                                    />
                                ) : null}
                            </div>
                        </>
                    ) : null}
                    <NutritionInfo
                        profile={{ ...profile, portion: 1 }}
                        nutrients={dishNutrients}
                        showAllAvailable={showAllAvailable}
                    />
                </section>
            );
        });

        const { content } = getPrimaryMeal([meals[dishIndex]], recipes, foods);

        const defaultTab = nutrientsIndex == 0 ? 'Combined' : 'Dish ' + nutrientsIndex;

        return (
            <div className={classNames('details-section', sections[this.section++])}>
                <header>
                    <div className="section-title">
                        <p className="t2">Nutrition Information</p>
                    </div>

                    {content && this.section == 1
                        ? this.renderMealControlButtons(meals[dishIndex], content, true)
                        : null}
                </header>

                <div className="section-body">
                    {nutrientTabs && nutrientTabs.length > 2 ? (
                        <MyTabs defaultTab={defaultTab} className="pill-tabs" htmlRef="nutrientTabs">
                            {nutrientTabs}
                        </MyTabs>
                    ) : (
                        nutrientTabs[1]
                    )}
                </div>
            </div>
        );
    };

    renderMealDetails = () => {
        const { dishIndex, meals, profile } = this.state;
        const { recipes, foods } = this.context;

        if (!(meals && meals[dishIndex])) {
            return (
                <div className="details-loading el-fonts">
                    <h4>Loading...</h4>
                    <i className="icon-spinner2" />
                </div>
            );
        }

        const meal = meals[dishIndex];

        const dish = getPrimaryMeal([meal], recipes, foods);
        const { primary, content } = dish;

        if (!(primary && content)) {
            return (
                <div className="details-loading el-fonts">
                    <h4>Loading...</h4>
                    <i className="icon-spinner2" />
                </div>
            );
        }

        if (content.type === 'recipe') {
            return this.renderRecipe(meal, content);
        }
        if (content.type === 'food') {
            return this.renderFood(meal, content);
        }
    };

    closeModal = () => {
        const { router } = this.context;
        const { closeModal } = this.props;

        if (closeModal) {
            closeModal();
            return;
        }

        router?.location?.state?.from ? router.push(router.location.state.from) : router.push('/meals');
    };

    scrollToDishIndex = (dishIndex, nutrientsIndex) => {
        const { meals } = this.state;
        const { location } = this.props;
        const { router } = this.context;
        const { pathname, query, hash } = location;

        if (!(meals && meals[dishIndex])) {
            return;
        }

        this.setState({ dishIndex }, () => {
            if (this.nutrientTabsRef.current?.setTab) {
                if (nutrientsIndex) {
                    this.nutrientTabsRef.setTab('Dish ' + nutrientsIndex);
                } else {
                    this.nutrientTabsRef.setTab('Combined');
                }
            }

            if (dishIndex != query.dishIndex) {
                const newQuery = Object.assign(query, {dishIndex});
                router.push({pathname, query: newQuery, hash});
            }
        });
    };

    removeMealsFromGroceries = (meals) => {
        const { removeMealsFromGroceries } = this.context;
        const { date, mealType } = this.state;

        removeMealsFromGroceries(meals);

        Analytics.removeMealFromGroceries(meals, mealType, date);
    };

    renderGroceryButton = () => {
        const { meals, primary, date, mealType } = this.state;
        const { recipes, foods, isPro } = this.context;

        if (!primary?.primary || isPro) {
            return;
        }

        if (!['fresh', 'food'].includes(primary.primary.meal_type)) {
            return;
        }

        // Are all these meals excluded from the grocery list?
        const excludedMeals = meals.filter((meal) => {
            if (meal.deleted) {
                return true;
            }

            const { content } = getPrimaryMeal([meal], recipes, foods);

            return content && content.exclude_from_groceries;
        });

        if (excludedMeals.length === meals.length) {
            return;
        }

        const mealOrders = MealStore.getOrdersForMeals(meals);

        return (
            <GroceryButton
                showAlert
                className="el-toolbar-cta-btn grocery-btn-style-2"
                defaultPopupPositionClass={'el-popup-top-right'}
                orders={mealOrders}
                meals={meals.filter((m) => !m.deleted)}
                primary={primary.primary}
                mealType={mealType}
                date={date}
            />
        );
    };

    getProtection = () => {
        const { primary } = this.state;
        const { recipe, food } = primary;

        let protection = 'public';

        if (recipe) {
            protection = recipe.protection;
        }

        if (food) {
            protection = food.protection;
        }

        return protection;
    };

    renderScheduleItButton = () => {
        const { profile } = this.state;
        const { capabilities = {} } = profile || {};

        const protection = this.getProtection();

        return (
            <button
                className="el-toolbar-green-pill-btn schedule-btn"
                onClick={() => this.setState({ isScheduling: true })}
            >
                schedule it
                {protection !== 'public' && !capabilities.meal_planner ? <i className="icon-lock" /> : null}
            </button>
        );
    };

    getLeftoverDateOverlaps = (profile, recipe, scaling, participants, date, mealType, loggedServings) => {
        const { meals } = this.context;
        let overlaps = [],
            clears = [];

        if (!profile.preferences.leftovers_enabled) {
            return { clears: [], overlaps: [] }; // no leftovers enabled, disabled by the users preferences
        }

        let tags = (recipe && recipe.tags) || [];

        // Do we have a no leftovers tag? Don't bother creating leftovers records.
        if (tags.indexOf('No Leftovers') != -1) {
            return { clears: [], overlaps: [] }; // no leftovers, not a good recipe for it.
        }

        const needed = getServingsNeededWithLoggedServings(profile, participants, loggedServings);

        if (!scaling) {
            scaling = Math.ceil(needed / recipe.servings);
        }

        const totalServings = recipe.servings * scaling;
        const totalLeftovers = Math.floor(totalServings / needed) - 1;

        let i = 1;
        while (i <= totalLeftovers && i <= 4) {
            const leftoverDate = moment(date).add(i++, 'day');
            const toOverwrite = meals.filter(
                (meal) =>
                    meal.meal_type !== 'leftover' && meal.meal === mealType && leftoverDate.isSame(meal.date, 'day')
            );

            if (toOverwrite.length > 0) {
                overlaps.push(leftoverDate);
            } else {
                clears.push(leftoverDate);
            }
        }

        return { clears, overlaps };
    };

    getOverlaps = (mealsToReschedule, date, mealType) => {
        let overlaps = [],
            clears = [];
        const { profile } = this.state;
        const { meals, recipes, foods } = this.context;
        const { primary, recipe } = getPrimaryMeal(mealsToReschedule, recipes, foods);

        const uuidsToReschedule = mealsToReschedule.map((m) => m.uuid);

        // Are there meals at the destination?
        const destMeals = meals.filter(
            (m) =>
                !m.deleted &&
                m.meal === mealType &&
                date.isSame(m.date, 'day') &&
                !uuidsToReschedule.includes(m.uuid) &&
                !uuidsToReschedule.includes(m.parent_uuid)
        );

        if (destMeals.length) {
            overlaps.push({
                mealType,
                date,
                destMeals,
                sourceMeals: mealsToReschedule,
            });
        } else {
            clears.push({
                mealType,
                date,
                sourceMeals: mealsToReschedule,
            });
        }

        if (primary.meal_type === 'fresh' && recipe) {
            const participants = getParticipantsForMeal(primary, profile);
            const loggedServings = getLoggedServingsOfRecipe(primary, recipe);
            const leftoverlaps = this.getLeftoverDateOverlaps(
                profile,
                recipe,
                primary.scaling,
                participants,
                date,
                mealType,
                loggedServings
            );

            leftoverlaps.overlaps?.forEach((leftoverDate) => {
                // Find the meals at the destination that would be overwritten.
                const destMeals = meals.filter(
                    (m) =>
                        m.meal === mealType &&
                        leftoverDate.isSame(m.date, 'day') &&
                        !uuidsToReschedule.includes(m.uuid) &&
                        !uuidsToReschedule.includes(m.parent_uuid)
                );

                const daysDiff = leftoverDate.diff(date, 'day');
                const leftoverTrueDate = moment(primary.date).add(daysDiff, 'day');

                // we're assuming that any other dishes have been scaled to the same settings as the primary.
                let sourceMeals = meals.filter(
                    (m) =>
                        m.meal_type === 'leftover' &&
                        leftoverTrueDate.isSame(m.date, 'day') &&
                        uuidsToReschedule.includes(m.parent_uuid)
                );

                // If there are already meals at our destination?
                if (destMeals.length && sourceMeals.length) {
                    overlaps.push({
                        date: leftoverDate,
                        mealType,
                        destMeals,
                        sourceMeals,
                    });
                } else {
                    clears.push({
                        mealType,
                        date: leftoverDate,
                        sourceMeals,
                    });
                }
            });

            leftoverlaps.clears?.forEach((clearDate) => {
                const daysDiff = clearDate.diff(date, 'day');
                const leftoverTrueDate = moment(primary.date).add(daysDiff, 'day');

                // we're assuming that any other dishes have been scaled to the same settings as the primary.
                let sourceMeals = meals.filter(
                    (m) =>
                        m.meal_type === 'leftover' &&
                        leftoverTrueDate.isSame(m.date, 'day') &&
                        uuidsToReschedule.includes(m.parent_uuid)
                );

                clears.push({ mealType, date: clearDate, sourceMeals });
            });
        }

        return { overlaps, clears, mealsToReschedule };
    };

    addToCalendar = (date, mealType = 'Dinner') => {
        const { profile, primary, meals } = this.state;
        const { router, showUpgradeForm } = this.context;

        if (!primary) {
            return false;
        }

        const protection = this.getProtection();

        const { capabilities = {} } = profile || {};

        if (protection !== 'public' && !capabilities.meal_planner) {
            showUpgradeForm({ feature: 'meal_planner' });
            return false;
        }

        let dateFormatted = date.format('YYYY-MM-DD');

        const { overlaps, clears } = this.getOverlaps(meals, date, mealType);

        if (!overlaps.length) {
            meals.forEach((meal) => {
                meal.date = dateFormatted;
                meal.meal = mealType;
            });
            MealActions.upsertMeals(meals);
            router.push({ pathname: `/meals/${dateFormatted}/${mealType}` });
        } else {
            this.setState({
                isConfirmOverwriteOpen: true,
                mealsToReschedule: meals,
                confirmOverlaps: overlaps,
                confirmClears: clears,
                confirmDate: date,
                confirmMealType: mealType,
            });
        }

        return true;
    };

    closeConfirmOverwriteModal = () => {
        this.setState({
            isConfirmOverwriteOpen: false,
            confirmOverlaps: null,
            confirmClears: null,
            confirmDate: null,
            confirmMealType: null,
        });
    };

    renderConfirmOverwriteModal = () => {
        const {
            isConfirmOverwriteOpen,
            mealsToReschedule,
            confirmOverlaps,
            confirmClears,
            confirmDate,
            confirmMealType,
        } = this.state;

        if (!isConfirmOverwriteOpen || !mealsToReschedule) {
            return null;
        }

        return (
            <ConfirmOverwriteModal
                meals={mealsToReschedule}
                onModifyMeals={this.onModifyMeals}
                overlaps={confirmOverlaps}
                isRepeatMeal={false}
                clears={confirmClears}
                date={confirmDate}
                mealType={confirmMealType}
                closeModal={this.closeConfirmOverwriteModal}
            />
        );
    };

    onModifyMeals = (mealsToUpsert, mealsToDelete = [], leftoverDates = null) => {
        let { confirmDate, confirmMealType, profile } = this.state;
        const { router, meals, groceries, recipes, details, foods } = this.context;

        mealsToDelete = mealsToDelete || [];
        let grocerySyncMeals = [],
            upsertLeftovers = [];

        mealsToUpsert.forEach((meal) => {
            delete meal.deleted;

            if (leftoverDates && meal.meal_type === 'fresh') {
                // Next update the leftovers.
                const { leftovers, toRemove } = updateMealLeftovers(
                    meal,
                    meals,
                    recipes[meal.recipe_uuid],
                    profile,
                    leftoverDates
                );

                // Append the updated leftovers, and any leftovers that need to be removed, to our working lists.
                upsertLeftovers = upsertLeftovers.concat(leftovers);
                mealsToDelete = mealsToDelete.concat(toRemove);
            } else if (meal.meal_type === 'fresh' && !leftoverDates) {
                meals.forEach((item) => {
                    if (meal.uuid === item.parent_uuid) {
                        item.recipe_uuid = meal.recipe_uuid;
                        item.details_uuid = meal.details_uuid;

                        upsertLeftovers.push(item);
                    }
                });
            }

            // If this meal is already in the groceries and needs to be synchronized, sync it.
            if (isMealInGroceries(meal, groceries) && meal.scaling != meal.scaling_added_to_groceries) {
                grocerySyncMeals.push(meal);
            }
        });

        if (grocerySyncMeals.length > 0) {
            GroceryActions.syncMealsToGroceries(grocerySyncMeals, recipes, details, foods);
        }

        if (mealsToDelete && mealsToDelete.length > 0) {
            // Signal to the grocery list to remove ingredients too
            GroceryActions.removeMealsFromGroceries(mealsToDelete, recipes, details, foods);

            // Collect any leftovers for these meals as well.
            const uuidsToDelete = mealsToDelete.map((m) => m.uuid);
            const childrenToDelete = meals.filter((m) => uuidsToDelete.includes(m.parent_uuid));
            mealsToDelete = mealsToDelete.concat(childrenToDelete);

            // Mark all meals as deleted
            mealsToDelete.forEach((m) => (m.deleted = true));

            // Signal the Meal Store to remove the fresh meal & the leftovers
            MealActions.deleteMeals(mealsToDelete);
        }

        MealActions.upsertMeals(mealsToUpsert.concat(upsertLeftovers));

        const dateFormatted = confirmDate.format('YYYY-MM-DD');

        router.push({ pathname: `/meals/${dateFormatted}/${confirmMealType}` });
    };

    closeScheduleModal = () => {
        this.setState({ isScheduling: false });
    };

    renderSchedulingModal = () => {
        const { profile, isScheduling, primary } = this.state;

        if (!isScheduling) {
            return null;
        }

        const protection = this.getProtection();
        const { capabilities = {} } = profile || {};

        return (
            <SchedulingModal
                content={primary.primary}
                isMealModal={true}
                isLocked={protection !== 'public' && !capabilities.meal_planner}
                selectDate={this.addToCalendar}
                closeModal={this.closeScheduleModal}
            />
        );
    };

    editSingleMeal = (meal, ev) => {
        const { dishIndex } = this.state;
        const { groceries, confirm } = this.context;
        let i = this.state.meals.indexOf(meal);

        if (i != dishIndex) {
            return this.scrollToDishIndex(i);
        }

        ev && ev.stopPropagation();

        // Is this meal in the grocery list already?
        if (isMealInGroceries(meal, groceries)) {
            confirm(
                'Please remove this meal from the grocery list before editing it, or the grocery list may not match the recipe.',
                (accept) => false,
                (reject) => false,
                { rejectText: null }
            );

            return;
        }

        this.setState({ editMealModal: meal });
    };

    deleteSingleMeal = (meal, ev) => {
        const { confirm, onRemoveMeals, recipes, foods } = this.context;
        const { dishIndex } = this.state;
        let i = this.state.meals.indexOf(meal);

        if (i != dishIndex) {
            return this.scrollToDishIndex(i);
        }

        ev && ev.stopPropagation();

        const pm = getPrimaryMeal([meal], recipes, foods);

        confirm(
            <div>
                <p>
                    Remove <em>{pm.titles.join(', ')}</em> from {meal.meal}, {moment(meal.date).format('dddd, MMM Do')}?
                </p>
            </div>,
            (accept) => {
                // Move to the next dish
                if (i > 1) {
                    this.scrollToDishIndex(i - 1);
                } else {
                    this.scrollToDishIndex(0);
                }

                onRemoveMeals([meal]);
            },
            (reject) => false
        );
    };

    isPast = (meal) => {
        const { defaultMealType } = this.context;

        const mealI = properMealOrder.indexOf(meal.meal);
        const defaultMealIndex = properMealOrder.indexOf(defaultMealType);

        let mode;

        if (moment(meal.date).isSame(moment(), 'day')) {
            mode = 'current';
        } else if (moment(meal.date).isBefore(moment(), 'day')) {
            mode = 'past';
        }

        if (mode === 'current' && mealI < defaultMealIndex) {
            mode = 'past';
        }

        return mode == 'past';
    };

    renderEditMealServingsButton = () => {
        const { editMealBatches } = this.context;
        const { primary, meals, participants } = this.state;
        const { recipes, isPro } = this.context;

        if (!primary?.content) {
            return;
        }

        let totalTime = 0;

        meals.forEach((meal) => {
            totalTime =
                recipes[meal.recipe_uuid] &&
                recipes[meal.recipe_uuid].total_time &&
                recipes[meal.recipe_uuid].total_time > totalTime
                    ? recipes[meal.recipe_uuid].total_time
                    : totalTime;
        });

        const allMeals = isPro ? this.context.meals : MealStore.getMeals();
        let editMealServings = <div className="edit-meal-servings">&nbsp;</div>;

        if (primary.content.servings) {
            let dishes = [];

            if (primary.dishes) {
                dishes.push(primary.dishes > 1 ? primary.dishes + ' dishes' : '1 dish');
            }

            if (primary.beverages) {
                dishes.push(primary.beverages > 1 ? primary.beverages + ' beverages' : '1 beverage');
            }

            const totalLeftovers = allMeals.filter(
                (m) => m.meal_type == 'leftover' && !m.deleted && m.parent_uuid == primary.primary.uuid
            ).length;

            const totalParticipants = participants?.length || 1;

            editMealServings = (
                <div className="edit-meal-servings" onClick={() => editMealBatches && editMealBatches(primary.primary)}>
                    <section className="serves">
                        <span>Serves</span>{' '}
                        <span>
                            {totalParticipants} {totalParticipants === 1 ? 'person' : 'people'}
                        </span>
                    </section>

                    {totalLeftovers > 0 ? (
                        <section className="serves leftovers">
                            <span>Leftovers</span>
                            <span>
                                {totalLeftovers} {totalLeftovers === 1 ? 'day' : 'days'}
                            </span>
                        </section>
                    ) : null}

                    <section className="timings">
                        <p className="dish-count">{dishes.join(', ')}</p>
                        {totalTime ? (
                            <p>
                                {toHuman(totalTime, true)}
                                <br />
                                prep/cook
                            </p>
                        ) : null}
                    </section>
                </div>
            );
        }

        return editMealServings;
    };

    renderControlBar = () => {
        const { hidePrintBtn, params } = this.props;
        const { isMobile, startAddMeal, startReplaceMeal } = this.context;
        const { profile, primary, dishIndex, meals, isMismatched, isOverLimits } = this.state;
        const { recipes, foods, isPro } = this.context;

        if (!primary) {
            return;
        }

        const { inhibit_swap = false, rd_override = false } = profile.preferences;

        const isPast = this.isPast(primary.primary);

        const ctaBtn = isPast && !isPro ? this.renderScheduleItButton() : this.renderGroceryButton();

        // If we've met our nutrition needs OR we've gone over, default to the search foods & recipes engine
        // instead of smart choices engine.
        let addOpts = !isMismatched || isOverLimits ? { defaultMode: 'browser' } : {};

        const swapMenu =
            meals.length == 1 ? (
                <SwapMenu className="el-toolbar-btn" icon="icon-swap" primary={primary.primary} options={{fullBrowserParams: {defaultTypes: ['recipe']}}} />
            ) : (
                <Popup
                    positionClassName="el-popup-top-right"
                    className="el-toolbar-popup swap-popup"
                    button={
                        <>
                            <i className="icon-swap" /> SWAP
                        </>
                    }
                >
                    <h6>Swap</h6>
                    <SwapMenu className="swap-popup-btn" primary={primary.primary} button={<>Entire Meal</>} options={{fullBrowserParams: {defaultTypes: ['recipe']}}}  />

                    {meals.map((meal, i) => (
                        <SwapMenu
                            key={i}
                            className="swap-popup-btn"
                            primary={meal}
                            options={{
                                replace_single: true,
                                fullBrowserParams: { defaultTypes: ['recipe'] }
                            }}
                            button={
                                <>
                                    DISH {i + 1}: <em>{primary.titles[i]}</em>
                                </>
                            }
                        />
                    ))}
                </Popup>
            );

        return (
            <div className={classNames('control-bar-container el-toolbar1', !isMobile ? 'el-toolbar1-dark' : null)}>
                {!hidePrintBtn ? (
                    <RecipeToPdfButton
                        className="el-toolbar-popup"
                        positionClassName="el-popup-top-left"
                        button={
                            <>
                                <i className="icon-print" /> <em>print</em>
                            </>
                        }
                        recipe={primary.recipe ? primary.primary : null}
                        meal={primary.primary}
                    />
                ) : null}

                <MultiSharePopup className="el-toolbar-popup" meals={meals} />

                {startAddMeal ? (
                    <button
                        className="el-toolbar-btn add-btn"
                        onClick={() => startAddMeal(moment(params.date), params.mealType, addOpts)}
                    >
                        <i className="feather feather-plus" /> <em>add</em>
                    </button>
                ) : null}

                {(!inhibit_swap || rd_override) && startReplaceMeal ? swapMenu : null}

                {ctaBtn}
            </div>
        );
    };

    renderScrollHero = () => {
        const { breakpoint47em, isCordova, isMobile } = this.context;
        const { meals, dishIndex } = this.state;

        const { recipes, foods, brands, startReplaceMeal, onRemoveMeals } = this.context;
        const { profile } = this.state;

        let isSafariMobile = false;

        if (isMobile && !isCordova && platform.name === 'Safari') {
            isSafariMobile = true;
        }

        return (
            <SpringHero
                meals={meals.filter((m) => !['note'].includes(m.meal_type))}
                dishIndex={dishIndex}
                profile={profile}
                recipes={recipes}
                foods={foods}
                brands={brands}
                breakpoint47em={breakpoint47em}
                startReplaceMeal={startReplaceMeal}
                editSingleMeal={this.editSingleMeal}
                deleteSingleMeal={this.deleteSingleMeal}
                scrollToDishIndex={this.scrollToDishIndex}
                onRemoveMeals={onRemoveMeals}
                isSafariMobile={isSafariMobile}
            />
        );
    };

    closeEditMealModal = () => {
        this.setState({ editMealModal: null });
    };

    onSaveRecipe = (meal, recipe, details) => {
        const { profile } = this.state;
        const { onModifyMeals } = this.context;

        meal.recipe_uuid = recipe.uuid;
        meal.details_uuid = details.uuid;
        // Reset logged grams to default in case recipe grams_per_serving changed
        meal.logged_grams = Math.round(recipe.grams_per_serving * 1000) / 1000 * profile.portion;
        // Reset logged milliliters to default in case recipe milliliters_per_serving changed
        meal.logged_milliliters = Math.round(recipe.milliliters_per_serving * 1000) / 1000 * profile.portion;

        const otherMeals = MealStore.getMeals().filter(m => m.recipe_uuid === recipe.uuid && m.uuid !== meal.uuid);

        otherMeals.forEach(m => {
            const loggedServings = getLoggedServingsOfRecipe(m, recipe, false);
            m.logged_grams = Math.round(recipe.grams_per_serving * 1000) / 1000 * (loggedServings || profile.portion);
            m.logged_milliliters = Math.round(recipe.milliliters_per_serving * 1000) / 1000 * (loggedServings || profile.portion);
        });

        // If the recipe & details UUID changed, this should trigger a full sync.
        onModifyMeals([meal, ...otherMeals]);

        this.closeEditMealModal();
    };

    renderEditMealModal = () => {
        const { profile, editMealModal } = this.state;

        let editorComponent = null;

        if (!editMealModal) {
            return;
        }

        const { recipe_uuid, food_uuid } = editMealModal;

        let modalTitle = '';

        if (recipe_uuid) {
            modalTitle = 'Edit Recipe';
            editorComponent = (
                <RecipeEditor
                    uuid={recipe_uuid}
                    profile={profile}
                    closeModal={this.closeEditMealModal}
                    onSaveRecipe={(recipe, details) => this.onSaveRecipe(editMealModal, recipe, details)}
                />
            );
        } else if (food_uuid) {
            return (
                <FoodEditorModal
                    uuid={food_uuid}
                    modalTitle="Edit Custom Food"
                    contentLabel="Edit Recipe Details"
                    onSaveFood={() => {
                        Analytics.savedCustomFood({
                            Context: 'Meal Details',
                            'Food UUID': food_uuid,
                        });
                        this.closeEditMealModal();
                    }}
                    closeModal={this.closeEditMealModal}
                />
            );
        }

        if (!editorComponent) {
            return null;
        }

        return (
            <Modal
                isOpen={true}
                onRequestClose={this.closeEditMealModal}
                closeModal={this.closeEditMealModal}
                className="el-modal el-modal1"
                overlayClassName="el-modal-overlay"
                contentLabel="Edit Recipe Details"
            >
                <div className="el-modal-container el-modal1-container el-modal1-recipe-editor-container">
                    <header data-has-controls={false}>
                        <button className="el-modal-back-btn" onClick={this.closeEditMealModal}>
                            <span>Back</span>
                        </button>

                        <h2>{modalTitle}</h2>
                    </header>

                    <div className="el-modal-body-container el-modal1-body-container el-fonts">
                        {editorComponent}
                    </div>
                </div>
            </Modal>
        );
    };

    renderModalBody = () => {
        return (
            <>
                {this.renderScrollHero()}
                {this.renderMealDetails()}
                {this.renderNutritionTabs()}
            </>
        );
    };

    section = 0;

    render() {
        const { isMobile } = this.context;
        const { profile, date, mealType } = this.state;

        if (!profile) {
            return (
                <div className="my-account-login">
                    <Helmet title="Please sign-in or register | EatLove" />
                    <LoginForm defaultSignUp={false} />
                    <Footer />
                </div>
            );
        }

        let dateFormatted = date.format('dddd, MMM D');

        if (date.isSame(moment(), 'day')) {
            dateFormatted = 'Today';
        } else if (date.isSame(moment().add(1, 'day'), 'day')) {
            dateFormatted = 'Tomorrow';
        } else if (date.isSame(moment().subtract(1, 'day'), 'day')) {
            dateFormatted = 'Yesterday';
        }

        this.section = 0;

        return (
            <>
                <Helmet title={`${mealType} ${dateFormatted} | EatLove`} />
                <Modal
                    isOpen={true}
                    onRequestClose={this.closeModal}
                    closeModal={this.closeModal}
                    className="el-modal el-modal1"
                    overlayClassName="el-modal-overlay"
                    contentLabel="Meal Details"
                >
                    <div className="el-modal-container el-modal1-container content-details-modal-container meal-details">
                        <header data-has-controls={isMobile}>
                            <button className="el-modal-back-btn" onClick={this.closeModal}>
                                <span>Back</span>
                            </button>

                            <h2>
                                {isMobile ? (
                                    <>
                                        {dateFormatted}, <em>{mealType}</em>
                                    </>
                                ) : (
                                    'Meal Information'
                                )}
                            </h2>

                            {isMobile ? (
                                <>
                                    {this.renderEditMealServingsButton()}

                                    <div className="el-modal-controls el-modal1-controls">
                                        <header>{this.renderControlBar()}</header>
                                    </div>
                                </>
                            ) : null}
                        </header>

                        <div className="el-modal-body-container el-modal1-body-container el-fonts">
                            {!isMobile ? (
                                <div className="details-header">
                                    <h6>
                                        {dateFormatted}, <em>{mealType}</em>
                                    </h6>
                                    {this.renderEditMealServingsButton()}
                                    <header>{this.renderControlBar()}</header>
                                </div>
                            ) : null}

                            {this.renderModalBody()}
                        </div>
                    </div>

                    {this.renderEditMealModal()}
                    {this.renderSchedulingModal()}
                    {this.renderConfirmOverwriteModal()}
                </Modal>
            </>
        );
    }
}
