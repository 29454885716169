'use strict'

import url from 'url';
import { Link } from 'react-router';

import { getConfig } from '../../../utils/Env';

import ImgResized from '../../../components/Widgets/ImgResized.react';

import './CategoryHero.scss';

export default function CategoryHero(props) {
    const { module } = props;

    const { title, image_url, href, tags, description, cta_text } = module.data || {};

    const moduleContent = [
        image_url ? <ImgResized key="image" src={image_url} height={280} width={680} noImgGeometry={true} /> : null,
        <div key="text" className="module-text">
            <h2>{title}</h2>

            <p>{description}</p>
            <p className="tags">{(tags || []).join(', ')}</p>

            {cta_text ?
                <footer><button>{cta_text}</button></footer>
            : null}
        </div>
    ].filter(v => v);

    // is this href to another article? Let's use a <Link to={}> instead of an <a href="...">
    const parsed = href ? url.parse(href) : null;

    if (parsed && (parsed.hostname === 'www.eatlove.is' || parsed.hostname === getConfig('www_host'))) {
        return (
            <div className="landing-module category-hero-module">
                <Link to={parsed.pathname}>{moduleContent}</Link>
            </div>
        )
    }

    return (
        <div className="landing-module category-hero-module">
            <a href={href}>{moduleContent}</a>
        </div>
    );
}
