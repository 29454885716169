'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import moment from 'moment';
import UserActions from '../../actions/UserActions';
import MealStore from '../../stores/MealStore';

import uuid from 'uuid';

import { scrollTextFieldIntoView } from '../../utils/Keyboard';

import './EditDailyLog.scss';
import _ from 'lodash';

export default class EditWeight extends Component {


    constructor(props) {
        super(props);

        const {weight_kg, weight_lbs} = this.getWeightFromMeals(props);

        this.state = {
            isModalOpen: false,
            weight_kg: weight_kg,
            weight_lbs: weight_lbs,
            displayError: false,
            weightHasFocus: false,
        };

    }

    componentDidUpdate(prevProps) {
        const { meals, noteType, date} = this.props;

        let sameDay = !meals.length && !prevProps.meals.length;
        const prevDate = prevProps.meals[0] && prevProps.meals[0].date;
        const nextDate = meals[0] && meals[0].date;

        if(prevDate && nextDate) {
            sameDay = moment(prevDate).isSame(moment(nextDate), 'day');
        }

        if (!sameDay) {
            const {weight_kg, weight_lbs} = this.getWeightFromMeals(this.props);
            this.setState({weight_kg, weight_lbs})
        }
    }

    getWeightFromMeals = (props) => {
        const { date, meals } = props;

        let weight_kg = null, weight_lbs = null;

        meals.forEach(meal => {
            if (meal.weight_kg && weight_kg === null) {
                weight_kg = meal.weight_kg;
            }
        });

        weight_lbs        = weight_kg      ? _.round(weight_kg * 2.20462, 1)     : '';

        weight_kg         = weight_kg      ?  _.round(weight_kg, 1) : '';

        const allMeals = MealStore.getMeals().filter(m => m.meal_type === "note" && m.weight_kg && moment(m.date).isBefore(moment(date), 'day')).sort((a,b) => moment(a.date).diff(moment(b.date)));

        const previousEntry = allMeals && allMeals.length ? _.round(allMeals[allMeals.length - 1].weight_kg * 2.20462, 1) : 0;
        const previousDate = allMeals && allMeals.length ? moment(allMeals[allMeals.length - 1].date).format("dddd, MMMM Do YYYY") : null;

        return { weight_kg, weight_lbs, previousEntry, previousDate };
    }

    openWeightEditorModal = () => {
        const {weight_kg, weight_lbs} = this.getWeightFromMeals(this.props);
        this.setState({isModalOpen: true, weight_kg, weight_lbs});
    }

    closeModal = () => {
        this.setState({isModalOpen: false});
    }

    saveWeight = () => {
        const { date,  meals, user, onModifyMeals } = this.props;
        let { weight_kg} = this.state;

        // Is this today? Do we need to save a new weight_kg to the users profile?
        if (date.isSame(moment(), 'day') && user.weight_kg != weight_kg) {
            UserActions.updateSpecificMeta({weight_kg});
        }

        // Are there no note meals? Create one.
        if (!meals.length) {
            onModifyMeals([{
                uuid: uuid.v4(),
                meal_type: 'note',
                meal: 'daily',
                date: date.format('YYYY-MM-DD'),
                weight_kg,
            }]);

            this.closeModal();
            return;
        }

        const meal = meals[0];

        meal.weight_kg = weight_kg;
        onModifyMeals([meal], false, false, meals.filter(m => m !== meal));

        this.closeModal();
    }

    onChangeWeightKg = (ev) => {
        let weight_kg = ev.target.value;

        if (weight_kg >= 1000) {
            weight_kg = 999;
        }

        let weight_lbs = weight_kg ? Math.round(weight_kg * 2.20462) : '';
        let isZero = false;

        if (weight_kg < 0) {
            weight_kg = 0;
        }

        if (weight_kg === 0) {
            isZero = true;
        }

        this.setState({weight_kg, weight_lbs, displayError: isZero});
    }

    onChangeWeightLbs = (ev) => {
        let weight_lbs = ev.target.value;

        if (weight_lbs >= 1000) {
            weight_lbs = 999;
        }

        let weight_kg = weight_lbs ? Math.round(weight_lbs / 2.20462 * 100) / 100: '';
        let isZero = false;

        if (weight_lbs < 0) {
            weight_lbs = 0;
        }

        if (weight_kg < 0) {
            weight_kg = 0;
        }

        if (weight_kg === 0) {
            isZero = true;
        }

        this.setState({weight_kg, weight_lbs, displayError: isZero});
    }


    renderModal = () => {
        const { user, dateFormatted } = this.props;
        const { isModalOpen, weight_kg, weight_lbs, displayError, weightHasFocus} = this.state;

        if (!isModalOpen) {
            return;
        }

        return (
            <Modal isOpen={true}
                className="edit-timings-modal"
                overlayClassName="feed-modal-overlay"
                onRequestClose={this.closeModal}
                closeModal={this.closeModal}
                contentLabel="Edit recipe timings"
                closeTimeoutMS={250}>
                <div className="edit-daily-log-modal-container">
                    <header className="modal-header">
                        <button className="close-btn" onClick={this.closeModal}>
                            <i className="icon-close-x" />
                        </button>
                    </header>
                    <div className="edit-daily-log-modal-body daily-log-extras edit-weight-modal">
                        <h2>WEIGHT ENTRY</h2>
                        <label className="date-label">{dateFormatted}</label>
                        {user && user.units_mode === 'metric' ?
                            <div>
                                <input type="number" min="0" max="999" placeholder={weightHasFocus ? "": "Enter weight"} value={weight_kg}
                                    onChange={this.onChangeWeightKg}
                                    onFocus={() => this.setState({weightHasFocus: true})} onBlur={() => this.setState({weightHasFocus: false})} />
                                <label>kg</label>
                            </div>
                        : null}

                        {user && user.units_mode === 'english' ?
                            <div className="with-units">
                                <input type="number" min="0" max="999" placeholder={weightHasFocus ? "": "Enter weight"}  value={weight_lbs}
                                    onChange={this.onChangeWeightLbs}
                                    onFocus={() => this.setState({weightHasFocus: true})} onBlur={() => this.setState({weightHasFocus: false})} />
                                <label>lbs</label>
                            </div>
                        : null}
                        {displayError ? <p className="error_msg">Please enter a valid weight.</p> : null}
                    </div>

                    <footer>
                        <button className="cancel-btn" onClick={this.closeModal}>cancel</button>
                        <button className="save-btn" onClick={this.saveWeight}>save</button>
                    </footer>
                </div>
            </Modal>
        );
    }

    render = () => {
        const { user, meals} = this.props;
        const { previousEntry, previousDate, weight_kg, weight_lbs} = this.getWeightFromMeals(this.props);
        const { isModalOpen} = this.state;

        return (
                <section className="with-header biometrics">
                    <header><h3>Biometrics</h3></header>

                    {user && user.units_mode === 'metric' ?
                        <div className="with-units">
                            <span>
                                <label onClick={parseInt(weight_kg || 0) === 0 ? this.openWeightEditorModal : null}> {weight_kg || "- -"}</label>
                                <label>kg</label>
                            </span>

                            {weight_kg
                                ? <button className="edit-link-button"  onClick={this.openWeightEditorModal}>EDIT</button>
                                : <button className="add-notes-btn"  onClick={this.openWeightEditorModal}>Record Weight</button>
                            }
                            {!weight_kg && previousEntry && previousDate ?
                                (   <div className="previous-entry">
                                        <p>Last recorded:</p>
                                        <div>
                                            <p>{previousEntry} Ibs</p>
                                            <p>{previousDate}</p>
                                        </div>
                                    </div>
                                )
                            : null}
                        </div>
                    : null}

                    {user && user.units_mode === 'english' ?
                        <div className="with-units">
                            <span>
                                <label onClick={parseInt(weight_kg || 0) === 0 ? this.openWeightEditorModal : null}> {weight_lbs || "- -"}</label>
                                <label>lbs</label>
                            </span>
                            {weight_lbs
                                ? <button className="edit-link-button"  onClick={this.openWeightEditorModal}>EDIT</button>
                                : <button className="add-notes-btn"  onClick={this.openWeightEditorModal}>Record Weight</button>
                            }
                            {!weight_lbs && previousEntry && previousDate ?
                                (   <div className="previous-entry">
                                        <p>Last recorded:</p>
                                        <div>
                                            <p>{previousEntry} Ibs</p>
                                            <p>{previousDate}</p>
                                        </div>
                                    </div>
                                )
                            : null}
                        </div>
                    : null}
                    {this.renderModal()}
                </section>
        )
    }

}
