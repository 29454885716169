'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { Link } from 'react-router';
import moment from 'moment';
import store from 'store';
import cookie from 'cookie-monster';

import UserActions from '../../actions/UserActions';
import Analytics from '../../utils/Analytics';
import basicMealConditions from '../../tables/basic-conditions';

import './BasicMealsBanner.scss';

const LOCALSTORE_KEY = 'basic-feature';

export default class BasicMealsBanner extends Component {
    static contextTypes = {
        location: PropTypes.object,
        user: PropTypes.object,
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            isVisible: this.shouldShowBanner(props, context),
            drawer: false,
        };
    }

    componentDidMount = () => {
        setTimeout(() => this.setState({drawer: true}));

        if (this.state.isVisible) {
            Analytics.basicMealsImpression();
        }
    }

    shouldShowBanner = (props, context) => {
        const { location, user } = context;

        // Don't show the banner on a few pages
        if (location && location.pathname != '/') {
            return false;
        }

        // Do we have a localstore flag indicating we've set the cookie?
        if (store.get(LOCALSTORE_KEY)) {
            // Ensure the cookie is set too
            cookie.setItem(LOCALSTORE_KEY, '1', {expires: moment().add(10, 'years').toDate().toUTCString()});
            return false;
        }

        if (cookie.getItem(LOCALSTORE_KEY)) {
            // Why didn't we have the localstorage copy? who cares, set it
            store.set(LOCALSTORE_KEY, true);
            return false;
        }

        // Do not show if account does not have meal planner capabilities
        if (!(user && user.capabilities && user.capabilities.meal_planner)) {
            return false;
        }

        const { conditions = [], preferences = {}, target_energy_kcal } = user || {};
        const { diets = [], avoidances = [], limit_tags = [], hide_nutrition = false } = preferences;

        // Is the Basic tag already turned on?
        if (limit_tags.includes('Basic')) {
            return false;
        }

        // Is this person on general healthy diet (or other approved conditions?)
        if (conditions.filter(cd => basicMealConditions.includes(cd.name)).length !== conditions.length) {
            return false;
        }

        // Is this person a vegan, dairy free, or gluten free? They're not eligible.
        if (diets.includes('Vegan') || avoidances.includes('milk') || avoidances.includes('wheat')) {
            return false;
        }

        // If their target energy range is outside of 1400-2400, they're not eligible.
        if (target_energy_kcal < 1400 || target_energy_kcal > 2400) {
            return false;
        }

        if (hide_nutrition) {
            return false;
        }

        return true;
    }

    closeModal = () => {
        this.setState({drawer: false}, () => {
            setTimeout(() => {
                // Set both cookie and localstorage
                store.set(LOCALSTORE_KEY, true);
                cookie.setItem(LOCALSTORE_KEY, '1', {expires: moment().add(10, 'years').toDate().toUTCString()});
                this.setState({isVisible: false});
            }, 350);
        });
    }

    addBasicLimitTag = () => {
        const { users } = this.context;
        let { preferences } = users || {};

        preferences = preferences || {};
        preferences.limit_tags = preferences.limit_tags || [];

        if (!preferences.limit_tags?.includes('Basic')) {
            preferences.limit_tags.push('Basic');
        }

        this.setState({working: true});

        Analytics.basicMealsAccept();

        UserActions.updateSpecificMeta({preferences}).then(
            success => {
                this.setState({working: false});
                this.closeModal();
            },
            error => {
                this.setState({working: false, error: error.field, alert: error.message});
            }
        );
    }

    render = () => {
        const { isVisible, drawer } = this.state;

        if  (!isVisible) {
            return <span />;
        }

        return (
            <div className="basic-meals-banner" data-drawer={drawer}>
                <div className="inner-container">
                    <h3>TRY OUR NEW SIMPLE RECIPES</h3>
                    <p>Due to COVID-19, grocery store inventory may be limited. Try our new Simple Recipe setting which will focus on nutritious recipes requiring <em>less ingredients</em> and <em>less time</em>.</p>

                    <footer>
                        <button className="no-btn" onClick={this.closeModal}>No thanks</button>
                        <button className="yes-btn" onClick={this.addBasicLimitTag}>Yes, let's try it</button>
                    </footer>
                </div>
            </div>
        );
    }
}
