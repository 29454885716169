'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import AuthStore from '../../stores/AuthStore';
import UserStore from '../../stores/UserStore';
import UserActions from '../../actions/UserActions';
import { getConfig } from '../../utils/Env';

import './Dashboard.scss';

export default class Dashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render = () => {
        const user = UserStore.getUser();

        if (user.role != 'admin') {
            return (
                <section>
                    <header><h1>Permission denied</h1></header>
                    <p>You do not have permissions to use the admin dashboard.</p>
                </section>
            );
        }

        return (
            <div className="admin-dashboard" >

                <div className="tool-buttons">
                    <Link to="/admin/foods">
                        <button className="foods-db-btn">
                            <i className="icon-cart" />
                            <br />
                            Food<br />Manager
                        </button>
                    </Link>

                    <Link to="/admin/categories">
                        <button className="category-db-btn">
                            <i className="icon-hierarchy" />
                            <br />
                            Category<br />Manager
                        </button>
                    </Link>

                    <Link to="/admin/combos">
                        <button className="combos-db-btn">
                            <i className="icon-meal-plan" />
                            <br />
                            Combo<br />Manager
                        </button>
                    </Link>

                    <Link to="/admin/brands">
                        <button className="outside-foods-btn">
                            <i className="icon-snacks2" />
                            <br />
                            Brands<br />Manager
                        </button>
                    </Link>

                    <Link to="/admin/newsletters">
                        <button className="newsletters-btn">
                            <i className="icon-email2" />
                            <br />
                            Newsletter<br />Manager
                        </button>
                    </Link>

                    <Link to="/admin/accounts">
                        <button className="accounts-btn">
                            <i className="icon-patients1" />
                            <br />
                            Accounts<br />Manager
                        </button>
                    </Link>

                    <Link to="/admin/pages">
                        <button className="pages-btn">
                            <i className="icon-stacks" />
                            <br />
                            Page<br />Manager
                        </button>
                    </Link>

                    <Link to="/admin/style-guide">
                        <button className="test-page-btn">
                            <i className="icon-toolkit1" />
                            <br />
                            Style<br />Guide
                        </button>
                    </Link>

                </div>
            </div>
        );
    }
}
