'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from 'react-modal';

import ConfirmOverwriteModal from './ConfirmOverwriteModal.react';

import { getPrimaryMeal, getRepeatOverlaps } from '../../../utils/Meals';

import './MealRepeatModal.scss';

const properMealOrder = ['Breakfast', 'Snack', 'Lunch', 'Dinner'];

export default class MealRepeatModal extends Component {
    static propTypes = {
        closeModal: PropTypes.func,
        meals: PropTypes.array,
        onModifyMeals: PropTypes.func,
    };

    static defaultProps = {
    };

    static contextTypes = {
        user: PropTypes.object,
        meals: PropTypes.array,
        recipes: PropTypes.object,
        foods: PropTypes.object,
        defaultMealType: PropTypes.string,
    };

    constructor(props, context) {
        super(props, context);

        const { meals } = props;
        const { recipes, foods } = context;
        const { primary, titles } = getPrimaryMeal(meals, recipes, foods);

        const date = moment(primary.date);
        const ogDate = date ? moment(date) : moment();
        const ogMealType = primary.meal;

        const uuidsToRepeat = meals.map(m => m.uuid);
        const ogLeftovers = context.meals.filter(m => !m.deleted && uuidsToRepeat.includes(m.parent_uuid));
        let ogLeftoverDates = ogLeftovers.map(m => m.date);
        ogLeftoverDates = ogLeftoverDates.filter((d, i) => ogLeftoverDates.indexOf(d) === i);

        this.state = {
            selection: [],
            ogTitles: titles,
            primary,

            uuidsToRepeat,
            ogDate, // original "gansta" date
            ogMealType,
            ogLeftovers,
            ogLeftoverDates,

            endOffset: 7,
        };
    }

    showLater = () => {
        let { endOffset } = this.state;

        endOffset += 7;

        if (endOffset > 28) {
            endOffset = 28;
        }

        this.setState({endOffset});
    }

    isOgMeal = (date, mealType) => {
        return (date.isSame(this.state.ogDate, 'day') && this.state.ogMealType === mealType);
    }

    selectMealTypeDate = (date, mealType) => {
        let { selection } = this.state;

        let selected = selection.find(sel => sel.date.isSame(date, 'day') && sel.mealType === mealType);

        if (selected) {
            selection.splice(selection.indexOf(selected), 1);
        } else {
            selection.push({date, mealType});
        }

        this.setState({selection});
    }

    isMealTypeDateSelected = (date, mealType) => {
        const { selection } = this.state;

        const selected = selection.find(sel => sel.date.isSame(date, 'day') && sel.mealType === mealType);

        return selected ? true : false;
    }

    isSelectionLeftover = (date, mealType) => {
        const { selection, ogLeftoverDates } = this.state;

        if (ogLeftoverDates.length === 0) {
            return false;
        }

        let isSelectionLeftover = false;

        selection.forEach(sel => {
            if (sel.mealType !== mealType) {
                return;
            }

            if (date.isSameOrBefore(sel.date, 'day')) {
                return;
            }

            const selLeftoverEnd = moment(sel.date).add(ogLeftoverDates.length, 'day');

            if (date.isSameOrBefore(selLeftoverEnd, 'day')) {
                isSelectionLeftover = true;
            }
        });

        return isSelectionLeftover;
    }

    range(min, max) {
        let arr = [];

        for(var i = min; i < max; i++) {
            arr.push(i);
        }

        return arr;
    }

    getRepeatOverlaps = (mealsToRepeat, selection) => {
        const { meals: allMeals, recipes, foods, user } = this.context;
        const { uuidsToRepeat, ogLeftoverDates } = this.state;

        return getRepeatOverlaps(
            mealsToRepeat,
            selection,
            uuidsToRepeat,
            ogLeftoverDates,
            allMeals,
            recipes,
            foods,
            user
        );
    }

    logPastMeals = (dirtyMeals) => {
        dirtyMeals.forEach(meal => {
            if (this.isPast(meal)) {
                meal.logged_portion = 1; // always default these to "1"

            }
        })

        return dirtyMeals;
    }

    completeRepeatMeals = (clears) => {
        const { onModifyMeals, closeModal } = this.props;

        let dirtyMeals = [];

        clears.forEach(({sourceMeals, mealType, date}) => {
            sourceMeals.forEach(meal => {
                meal.meal = mealType;
                meal.date = date.format('YYYY-MM-DD');

                if (dirtyMeals.indexOf(meal) === -1) {
                    dirtyMeals.push(meal);
                }
            });
        });

        dirtyMeals = this.logPastMeals(dirtyMeals);
        onModifyMeals(dirtyMeals);

        closeModal(true);
    }

    onRepeatMeal = () => {
        const { meals } = this.props;
        const { selection } = this.state;

        if (!selection.length) {
            this.setState({error: 'Please select where you\'d like to repeat this meal'});
            return;
        }

        const { overlaps, clears } = this.getRepeatOverlaps(meals, selection);

        // If there are no overlaps, just reschedule the meal right now
        if (!overlaps.length) {
            return this.completeRepeatMeals(clears);
        }

        // There are overlaps. We need to display the confirm overwrite modal. It will
        // perform all the additional meal operations based upon the users answers.
        const newState = {
            isConfirmOverwriteOpen: true,
            confirmOverlaps: overlaps,
            confirmClears: clears,
        };

        this.setState(newState);
    }

    closeModal = (allDone) => {
        const { closeModal } = this.props;

        if (allDone === true) {
            return closeModal();
        }

        this.setState({isConfirmOverwriteOpen: false});
    }

    renderConfirmOverwriteModal = () => {
        const { isConfirmOverwriteOpen, confirmOverlaps, confirmClears } = this.state;
        const { meals, onModifyMeals } = this.props;

        if (!isConfirmOverwriteOpen) {
            return null;
        }

        return <ConfirmOverwriteModal meals={meals}
                    onModifyMeals={onModifyMeals}
                    overlaps={confirmOverlaps}
                    clears={confirmClears}
                    isRepeatMeal={true}
                    closeModal={this.closeModal}
                    logPastMeals={this.logPastMeals} />
    }



    isPast = (meal) => {
        const { defaultMealType } = this.context;

        const mealI = properMealOrder.indexOf(meal.meal);
        const defaultMealIndex = properMealOrder.indexOf(defaultMealType);

        let mode;

        if (moment(meal.date).isSame(moment(), 'day')) {
            mode = 'current';

        } else if (moment(meal.date).isBefore(moment(), 'day')) {
            mode = 'past';
        }

        if (mode === 'current' && mealI < defaultMealIndex) {
            mode = 'past';
        }

        return mode == 'past';
    }

    render() {
        const { error, ogDate, endOffset, ogTitles } = this.state;
        const { meals, closeModal } = this.props;
        const { meals: allMeals, recipes, foods } = this.context;

        const ogUUIDs = meals.map(m => m.uuid);

        return (
            <Modal isOpen={true}
                onRequestClose={closeModal}
                closeModal={closeModal}
                className="meal-repeat-modal"
                contentLabel="Reschedule Meal"
                overlayClassName="feed-modal-overlay"
                closeTimeoutMS={250}>
                <div className="edit-meal-modal-container meal-repeat-modal-container">
                    <header>
                        <button onClick={closeModal} className="close-btn">
                            <i className="icon-close-x" />
                            <span className="assistive-text">Close Modal</span>
                        </button>
                    </header>

                    <div className="modal-scroll-container">
                        <header>
                            <h1>Repeat Meal</h1>
                            <h3>{ogTitles.join(' + ')}</h3>
                        </header>

                        <div className="upcoming-dates-picker">
                            {this.range(0, endOffset).map(i => {
                                const buttonDate = moment(ogDate).add(i, 'day');
                                let dateName = buttonDate.format('ddd, MMM Do');

                                if (moment().subtract(1, 'day').isSame(buttonDate, 'day')) {
                                    dateName = 'Yesterday';
                                } else if (moment().isSame(buttonDate, 'day')) {
                                    dateName = 'Today';
                                } else if (moment().add(1, 'day').isSame(buttonDate, 'day')) {
                                    dateName = 'Tomorrow';
                                }

                                return (
                                    <div data-testid={dateName} key={i}>
                                        <h4>{dateName}</h4>
                                        <ul data-testid={"ul-" + dateName} >
                                            {['Breakfast', 'Snack', 'Lunch', 'Dinner'].map(mealType => {
                                                const mealTypeDateMeals = allMeals.filter(m => m.meal === mealType && buttonDate.isSame(m.date, 'day'));
                                                const { primary, titles } = getPrimaryMeal(mealTypeDateMeals, recipes, foods);
                                                const isOgMeal = this.isOgMeal(buttonDate, mealType);
                                                const isOgLeftover = mealTypeDateMeals.filter(m => ogUUIDs.includes(m.parent_uuid)).length > 0;
                                                const isSelectionLeftover = this.isSelectionLeftover(buttonDate, mealType);

                                                let disabled = isOgMeal || isOgLeftover || isSelectionLeftover;
                                                let isSelected = isOgMeal || isSelectionLeftover || this.isMealTypeDateSelected(buttonDate, mealType);

                                                return (
                                                    <li data-testid={`li-${dateName}-${mealType}`} key={mealType}>
                                                        <button className={"meal-toggle-btn"}
                                                            onClick={() => this.selectMealTypeDate(buttonDate, mealType)}
                                                            disabled={disabled}
                                                            data-selected={isSelected}>
                                                            <em>{mealType}</em>
                                                            {mealTypeDateMeals.length > 0 && !isSelected ?
                                                                <span>{(primary && primary.meal_type === 'leftover') ? 'LEFTOVER: ' : null}{titles.join(' + ')}</span>
                                                            : null}
                                                            {isSelected ?
                                                                <span>{ogTitles.join(' + ')}</span>
                                                            : null}
                                                        </button>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                )
                            })}

                            {endOffset < 28 ? <button className="more-dates-btn" onClick={this.showLater}>show more</button> : null}
                        </div>
                    </div>

                    <footer>
                        {error ? <p className="error-msg">{error}</p> : null}
                        <button data-testid="ok-btn" className="ok-btn" onClick={this.onRepeatMeal}>Save</button>
                    </footer>

                    {this.renderConfirmOverwriteModal()}
                </div>
            </Modal>
        );
    }
}
