'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Dropdown from '../../../pro/components/Widgets/Dropdown.react';
import { getParticipantsForProfileByMealType } from '../../../utils/Meals';


import './AddMissingMeal.scss';

export default class AddMissingMeal extends Component {

    static contextTypes = {
        user: PropTypes.object,
        meals: PropTypes.array,
        startAddMeal: PropTypes.func,
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            activeDate: moment(props.activeDate),

            shouldShow: this.shouldShowAddMissingButton(context.user, context.meals, props.activeDate),
        };
    }

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        this.setState({
            shouldShow: this.shouldShowAddMissingButton(nextContext.user, nextContext.meals, nextProps.activeDate),
        });
    }

    shouldShowAddMissingButton = (user, meals, activeDate) => {
        let shouldShow = [];

        if (!user) {
            return [];
        }

        ['Breakfast', 'Lunch', 'Dinner', 'Snack'].forEach(mealType => {

            // If any of the meals has zero participants AND there are no meals scheduled for that
            let participants = getParticipantsForProfileByMealType(user, mealType);
            let activeMeals = meals.filter(meal => activeDate.isSame(meal.date, 'day') && meal.meal === mealType);
            if (!participants.length && !activeMeals.length) {
                shouldShow.push(mealType);
            }
        });

        return shouldShow;
    }

    render() {
        const { shouldShow } = this.state;
        const { startAddMeal } = this.context;
        const { activeDate } = this.props;

        if (!shouldShow.length) {
            return <span />
        }

        if (shouldShow.length == 1) {
            return (
                <div className="add-missing-meals">
                    <button onClick={() => startAddMeal(activeDate, shouldShow[0])}>
                        <i className="icon-plus-thin" />
                    </button>
                </div>
            );
        }

        return (
            <div className="add-missing-meals">
                <Dropdown button={<i className="icon-plus-thin" />}>
                    <p>Add a meal</p>
                    <ul>{shouldShow.map(mealType => (
                        <li key={mealType}>
                            <button className="add-missing-meal-btn" onClick={() => startAddMeal(activeDate, mealType)}>
                                {mealType}
                            </button>
                        </li>
                    ))}</ul>
                </Dropdown>
            </div>
        );
    }
}
