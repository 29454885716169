'use strict';
import { Component } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import moment from 'moment';

import UserStore from '../../stores/UserStore';

import EditWeight from './EditWeight.react'
import EditNotes from './EditNotes.react'

import { roundForHumans } from '../../utils/Math';
import Analytics from '../../utils/Analytics';

import './Extras.scss';
import ExternalData from './ExternalData.react';
import MealActions from '../../actions/MealActions';

export default class Extras extends Component {
    static propTypes = {
        meals:  PropTypes.array,
        mealType: PropTypes.string,
    };

    static contextTypes = {
        user: PropTypes.object,
        onModifyMeals: PropTypes.func,
    }

    constructor(props) {
        super(props);

        this.state = {
            ...this.getStateFromMeals(props),
        };

    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        // If  we're not editing, just update our state.
        this.setState(this.getStateFromMeals(nextProps));
    }

    getStateFromMeals = (props) => {
        const { date, meals } = props;

        let water_intake_l = null, water_intake_floz = null;

        meals.forEach(meal => {
            if (meal.water_intake_l && water_intake_l === null) {
                water_intake_l = meal.water_intake_l;
            }
        });

        if (water_intake_l === null) {
            water_intake_l = 0;
        }

        water_intake_floz = water_intake_l ? Math.round(water_intake_l * 33.814) : '';

        return { water_intake_l, water_intake_floz,};
    }

    saveWater = () => {
        const { user, onModifyMeals } = this.context;
        const { date, meals } = this.props;
        let { water_intake_l, shouldUpdateProfile } = this.state;

        // Are there no note meals? Create one.
        if (!meals.length) {
            MealActions.upsertMealsWithoutQueue([{
                uuid: uuid.v4(),
                meal_type: 'note',
                meal: 'daily',
                date: date.format('YYYY-MM-DD'),
                water_intake_l,
            }]);

            return;
        }

        Analytics.saveWater({
            "Date": date.format('YYYY-MM-DD'),
            "Water Intake Liters": water_intake_l,
        });

        const meal = meals[0];

        meal.water_intake_l = water_intake_l;

        MealActions.upsertMealsWithoutQueue([meal]);

        // onModifyMeals([meal], false, false, meals.filter(m => m !== meal));
    }


    reduceWater = (amt = null) => {
        const { user } = this.context;

        if (amt === null && user.units_mode == 'english') {
            amt = 0.236588;
        } else if (amt === null) {
            amt = .25; // 1/4 liter
        }

        let { water_intake_l } = this.state;

        water_intake_l -= amt;

        if (water_intake_l < 0) {
            water_intake_l = 0;
        }

        let water_intake_floz = Math.round(water_intake_l * 33.814);

        this.setState({water_intake_l, water_intake_floz}, this.saveWater);
    }

    increaseWater = (amt = null) => {
        const { user } = this.context;

        if (amt === null && user.units_mode == 'english') {
            amt = 0.236588;
        } else if (amt === null) {
            amt = .25; // 1/4 liter
        }

        let { water_intake_l } = this.state;

        water_intake_l += amt;

        let water_intake_floz = Math.round(water_intake_l * 33.814);

        this.setState({water_intake_l, water_intake_floz}, this.saveWater);
    }

    renderWaterIntake = () => {
        let { water_intake_l } = this.state;

        if (!water_intake_l) {
            water_intake_l = 0;
        }

        let full = Math.round(water_intake_l / 0.236588);

        let empty = 8 - full;

        var i = 0;

        const fullIcon = (
            <svg width="28" height="38" viewBox="0 0 28 38">
                <defs>
                    <linearGradient id="a" x1="0%" y1="50%" y2="50%">
                        <stop offset="0%" stopColor="#BDF2FA"/>
                        <stop offset="100%" stopColor="#6DC8E5"/>
                    </linearGradient>
                    <linearGradient id="b" x1="50%" x2="50%" y1="0%" y2="100%">
                        <stop offset="0%" stopColor="#4CD3F6"/>
                        <stop offset="100%" stopColor="#21B5DA"/>
                    </linearGradient>
                </defs>
                <g fill="none" fillRule="nonzero">
                    <path fill="url(#a)" d="M3.245 19.672C1.511 18.26.588 10.212 1.511 9.223c1.512-1.622 10.634 3.755 12.362 3.84 1.87-.09 9.945-5.532 11.91-3.84 1.452 1.25-.684 9.073-2.04 10.449.264 7.033.125 11.033-.417 12-1.682 3-8.047 3.756-9.826 3.756-1.776 0-8.75-1.434-9.87-4.24-.745-1.87-.874-5.709-.385-11.516z" transform="translate(.5 .328)"/>
                    <path fill="#A30063" d="M2.5 20.113C1.26 17.031.01 11.929.726 6.91a.75.75 0 0 1 1.485.212c-.68 4.76-.237 8.516.905 11.352.262.652.608 1.197.884 1.639.159.254.208 11.412.263 11.476a.75.75 0 0 1 .124.2c.67 1.606 5.112 3.758 9.738 3.758 4.626 0 9.068-2.152 9.738-3.758a.75.75 0 0 1 .124-.2c.055-.064.172-11.222.33-11.476.276-.442.555-.987.817-1.64 1.142-2.835 1.585-6.592.905-11.351a.75.75 0 1 1 1.485-.212c.717 5.019-.484 10.12-1.724 13.203-.477 1.185-.238 11.914-.602 12.366-1.056 2.22-5.987 4.568-11.073 4.568S4.108 34.699 3.052 32.479c-.364-.452-.075-11.181-.552-12.366z"/>
                    <path fill="#A30063" d="M14.125 13.39C6.767 13.39.719 10.704.719 7.017S6.767.64 14.125.64c7.358 0 13.406 2.688 13.406 6.375s-6.048 6.375-13.406 6.375zm0-1.5c6.621 0 11.906-2.348 11.906-4.874 0-2.527-5.285-4.875-11.906-4.875S2.219 4.489 2.219 7.016c0 2.526 5.285 4.875 11.906 4.875z"/>
                    <path fill="url(#b)" d="M2.09 7.837c2.509-2.074 8.1-3.165 11.531-3.165 3.276-.01 8.859 1.212 11.42 3.334-1.708 2.196-5.554 3.418-11.541 3.666-6.18-.306-9.984-1.585-11.41-3.835z" transform="translate(.5 .328)"/>
                </g>
            </svg>
        );

        const addIcon = (
            <svg width="28" height="38" viewBox="0 0 28 38">
                <g fill="none" fillRule="nonzero">
                    <path fill="#A8ACBE" d="M2.5 20.113C1.26 17.031.01 11.929.726 6.91a.75.75 0 0 1 1.485.212c-.68 4.76-.237 8.516.905 11.352.262.652.608 1.197.884 1.639.159.254.208 11.412.263 11.476a.75.75 0 0 1 .124.2c.67 1.606 5.112 3.758 9.738 3.758 4.626 0 9.068-2.152 9.738-3.758a.75.75 0 0 1 .124-.2c.055-.064.172-11.222.33-11.476.276-.442.555-.987.817-1.64 1.142-2.835 1.585-6.592.905-11.351a.75.75 0 1 1 1.485-.212c.717 5.019-.484 10.12-1.724 13.203-.477 1.185-.238 11.914-.602 12.366-1.056 2.22-5.987 4.568-11.073 4.568S4.108 34.699 3.052 32.479c-.364-.452-.075-11.181-.552-12.366z"/>
                    <path fill="#A8ACBE" d="M14.125 13.39C6.767 13.39.719 10.704.719 7.017S6.767.64 14.125.64c7.358 0 13.406 2.688 13.406 6.375s-6.048 6.375-13.406 6.375zm0-1.5c6.621 0 11.906-2.348 11.906-4.874 0-2.527-5.285-4.875-11.906-4.875S2.219 4.489 2.219 7.016c0 2.526 5.285 4.875 11.906 4.875z"/>
                    <path fill="#BAF4FF" d="M4.5 10c2.508-2.075 6.41-3.888 9.59-3.734 3.25-.154 6.85 1.612 9.41 3.734-2.523 1.333-5.69 2-9.5 2-3.667 0-6.833-.667-9.5-2z"/>
                    <path fill="#A30063" d="M13.25 20.324a.75.75 0 1 1 1.5 0v7.5a.75.75 0 0 1-1.5 0v-7.5z"/>
                    <path fill="#A30063" d="M10.25 24.824a.75.75 0 0 1 0-1.5h7.5a.75.75 0 1 1 0 1.5h-7.5z"/>
                </g>
            </svg>
        );

        const emptyIcon = (
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="38" viewBox="0 0 28 38">
                <g fill="none" fillRule="nonzero">
                    <path fill="#A8ACBE" d="M2.5 20.113C1.26 17.031.01 11.929.726 6.91a.75.75 0 0 1 1.485.212c-.68 4.76-.237 8.516.905 11.352.262.652.608 1.197.884 1.639.159.254.208 11.412.263 11.476a.75.75 0 0 1 .124.2c.67 1.606 5.112 3.758 9.738 3.758 4.626 0 9.068-2.152 9.738-3.758a.75.75 0 0 1 .124-.2c.055-.064.172-11.222.33-11.476.276-.442.555-.987.817-1.64 1.142-2.835 1.585-6.592.905-11.351a.75.75 0 1 1 1.485-.212c.717 5.019-.484 10.12-1.724 13.203-.477 1.185-.238 11.914-.602 12.366-1.056 2.22-5.987 4.568-11.073 4.568S4.108 34.699 3.052 32.479c-.364-.452-.075-11.181-.552-12.366z"/>
                    <path fill="#A8ACBE" d="M14.125 13.39C6.767 13.39.719 10.704.719 7.017S6.767.64 14.125.64c7.358 0 13.406 2.688 13.406 6.375s-6.048 6.375-13.406 6.375zm0-1.5c6.621 0 11.906-2.348 11.906-4.874 0-2.527-5.285-4.875-11.906-4.875S2.219 4.489 2.219 7.016c0 2.526 5.285 4.875 11.906 4.875z"/>
                    <path fill="#BAF4FF" d="M4.5 10c2.508-2.075 6.41-3.888 9.59-3.734 3.25-.154 6.85 1.612 9.41 3.734-2.523 1.333-5.69 2-9.5 2-3.667 0-6.833-.667-9.5-2z"/>
                </g>
            </svg>
        );

        const rendering = [];
        for (i = 0; i < full; i++) {
            rendering.push(<button className="full-water-btn" key={"full-"+i} onClick={() => this.reduceWater()}>{fullIcon}</button>);
        }

        rendering.push(<button className="add-water-btn" key={"add-"+i} onClick={() => this.increaseWater()}>{addIcon}</button>);
        empty--;

        for (i = 0; i < empty; i++) {
            rendering.push(<button className="empty-water-btn" key={"empty-"+i} onClick={() => this.increaseWater()}>{emptyIcon}</button>);
        }

        return rendering;
    }

    render() {
        let { meals, date } = this.props;
        const { water_intake_l, water_intake_floz } = this.state;
        const { onModifyMeals } = this.context;

        const user = UserStore.getUser();
        const { hide_nutrition = false } = (user && user.preferences) || {};

        const isPastOrCurrent = date.isSameOrBefore(moment(), 'day');
        const shouldShowWeight = (!hide_nutrition && isPastOrCurrent && meals);
        const shouldShowWater = isPastOrCurrent;

        let dateFormatted = '';

        if (date) {
            if (moment().subtract(1, 'day').isSame(date, 'day')) {
                dateFormatted = 'Yesterday, ' + date.format('MMM Do');
            } else if (moment().isSame(date, 'day')) {
                dateFormatted = 'Today, ' + date.format('MMM Do');
            } else if (moment().add(1, 'day').isSame(date, 'day')) {
                dateFormatted = 'Tomorrow, ' + date.format('MMM Do');
            } else {
                dateFormatted = date.format('dddd, MMM Do');
            }
        }

        return (
            <section className="daily-log-extras">
                {shouldShowWater ?
                    <section className="with-header water-intake">
                        <header><h3>Water Intake</h3></header>

                        <div className="water-btns">{this.renderWaterIntake()}</div>

                        {user && user.units_mode === 'metric' ?
                            <p data-testid="water-log">{roundForHumans(water_intake_l)} L</p>
                        : null}

                        {user && user.units_mode === 'english' ?
                            <p data-testid="water-log">{roundForHumans(water_intake_floz)} fl oz</p>
                        : null}
                    </section>
                : null}

                {shouldShowWeight ?
                    <EditWeight
                        user={user}
                        meals={meals}
                        onModifyMeals={onModifyMeals}
                        dateFormatted={dateFormatted}
                        date={date}/>
                : null}

                <section className="with-header daily-log-notes">
                    <header><h3>Exercise Notes</h3></header>

                    <ExternalData provider="fitbit" date={date} />

                    <EditNotes
                        user={user}
                        meals={meals}
                        onModifyMeals={onModifyMeals}
                        dateFormatted={dateFormatted}
                        date={date}
                        mealType={"daily"}
                        title={"Exercise"}
                        noteType={"exercise"}
                        isExtra/>
                </section>

                <section className="with-header daily-log-notes">
                    <header><h3>Daily Notes</h3></header>

                    <EditNotes
                        user={user}
                        meals={meals}
                        onModifyMeals={onModifyMeals}
                        dateFormatted={dateFormatted}
                        date={date}
                        title={"Daily"}
                        mealType={"daily"}
                        noteType={"notes"}
                        isExtra/>
                </section>

            </section>
        );
    }
}
